import { FormControl, FormLabel, Theme, SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren, useEffect } from "react";

export interface LabeledValueOptional{
    required? :boolean;
    sx?: SxProps<Theme>;
    /** Broj karaktera koliko je dugo polje, mora se proslediti value da bi imao 
     * u odnosu na sta da gleda len */
    maxLen?: number;
    value?:any;
}

export type LabeledValueProps = {
    label: string|JSX.Element;
}&LabeledValueOptional;

export default function LabeledValue(props: PropsWithChildren<LabeledValueProps>){

    useEffect(()=>{}, [props])

    return (
        <FormControl sx={{margin: "15px 10px", minWidth: "150px", display:"inline-flex", flex:1, justifyContent: "center", ...(props.sx || {})}}>
            <FormLabel required={props.required}>
                {props.label||""} {props.maxLen && `${String(props.value||"").length}/${props.maxLen}`}
            </FormLabel>
            <Box component={"div"}>{props.children}</Box>
        </FormControl>
    )
}