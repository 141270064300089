import { createSelector } from "@reduxjs/toolkit";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PregedAktivnosti } from "../model/administracija/korisnikSistema/pregledAktivnosti";
import ElektronskaDostava from "../model/eDostava/ElektronskaDostava";
import Korisnik from "../model/korisnikPortala/korisnikPortalaModel";
import { DigitResponse } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";
import { PartnerFilter } from "./ppartnerApiCalls";

const address = config["razvoj"]  ? config["srbijagas.kportala.razvoj"] : config["srbijagas.kportala"];
const domain = "/kPortala";

export function getAuthUserData(){
    let url = `${address}${domain}`;
    
    return fetch(url,signRequest({
        method:"GET",
        headers: {
            'Accept': 'application/json',
        },
    }))
}

export interface PromenaStatusaNaloga{
    korisnikId: number;
    opis?: string;
}

export interface PromenaStatusaEmailAdrese{
    edId?: number;
    idStatusa?: number;
}

export interface DeleteEmail {
    edId?: number;
}

export const korisnikApi = createApi({
    reducerPath: "korisnik",
    baseQuery: fetchBaseQuery({
        baseUrl: `${address}${domain}`,
        prepareHeaders: (headers)=>{
            return updateHeaders(headers);
        }
    }),
    endpoints: (builder)=>({
        getKorisnik : builder.query<DigitResponse<Korisnik>, PaginationQuery|void>({
            query: (page?: PaginationQuery)=> `${fixQuery(createQueryParams(page))}`
        }),
        getPregledAktivnosti: builder.query<DigitResponse<PregedAktivnosti[]>, void>({
            query: ()=> "pregledAktivnosti"
        }),
        getKorisnike : builder.query<DigitResponse<PageableWithData<Korisnik>>, PartnerFilter|void>({
            query: (page?: PartnerFilter)=> `korisnici${fixQuery(createQueryParams(page))}`
        }),
        postAktivirajKorisnika : builder.mutation<DigitResponse<string>, PromenaStatusaNaloga>({
            query: (body: PromenaStatusaNaloga)=> {
                return {
                    url: `aktiviraj`,
                    method: "POST",
                    body
                }
            }
        }),
        postBlokirajKorisnika : builder.mutation<DigitResponse<string>, PromenaStatusaNaloga>({
            query: (body: PromenaStatusaNaloga)=> {
                return {
                    url: `blokiraj`,
                    method: "POST",
                    body
                }
            }
        }),
        postDeaktivirajKorisnika : builder.mutation<DigitResponse<string>, void>({
            query: ()=> {
                return {
                    url: `deaktiviraj`,
                    method: "POST"
                }
            }
        }),
        getEmailDostave: builder.query<DigitResponse<ElektronskaDostava[]>, void>({
            query: ()=> `emailDostave`
        }),
        deleteEmail: builder.mutation<DigitResponse<string>, DeleteEmail>({
            query: (q: DeleteEmail)=> {
                return {
                    url: `emailDostave/delete/${q.edId}`,
                    method: "DELETE",
                }
            }
        }),
        dodajEmail: builder.mutation<DigitResponse<string>, ElektronskaDostava>({
            query: (body: ElektronskaDostava)=> {
                return {
                    url: `emailDostave/dodaj`,
                    method: "POST",
                    body
                }
            }
        }),
        postPromeniStatusMaila: builder.mutation<DigitResponse<string>, PromenaStatusaEmailAdrese>({
            query: (body : PromenaStatusaEmailAdrese) => {
                return {
                    url: `emailDostave/status`,
                    method: "POST",
                    body
                }
            }
        }),
        postPromenaLozinke: builder.mutation<DigitResponse<string>, FormData>({
            query: (body: FormData) => {
                return {
                    url: "promenaLozinke",
                    method: "POST",
                    body
                }
            }
        }),
    })
})

export const selectKorisnik = createSelector(
    korisnikApi.endpoints.getKorisnik.select(),
    (result) => result.data?.content != null ? result.data?.content : undefined
)

export const {useGetKorisnikQuery,
    useGetKorisnikeQuery,
    useGetPregledAktivnostiQuery,
    usePostAktivirajKorisnikaMutation,
    usePostBlokirajKorisnikaMutation,
    usePostDeaktivirajKorisnikaMutation,
    usePostPromeniStatusMailaMutation,
    useGetEmailDostaveQuery,
    useDeleteEmailMutation,
    useDodajEmailMutation,
    usePostPromenaLozinkeMutation,
} = korisnikApi