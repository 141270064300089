import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from "i18next";
import { ZahtevRegistracije } from "../model/administracija/korisnikSistema/zahtevRegistracije";
import { RegKorisnikaModel, RegKorisnikaModelBody } from "../model/registracija/registracijaModel";
import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, downloadPdfResource, downloadPdfResourceNoHeader, setLangHeader } from "./apiCalls";
import config from "./config.json";

const address = config["razvoj"]  ? config["srbijagas.registracija.razvoj"] : config["srbijagas.registracija"];
const domain = "/registracija";

export interface RegistracijaFilter extends Row, PaginationQuery{
    idRegistracije?: number,
    sifraPp?: number,
    jmbg?: string,
    imeprezime?: string,
}

export interface RegistracijaFilterRequest extends Row, PaginationQuery{
    jmbg?: string;
    pibLike?: string;
    mbrLike?: string;
}

export interface ZaboravljenaLozinkaBody {
    username : string;
}

/** Vraca page, namenjeno pretezno za admine za pretragu */
export function getRegistracije(query?: RegistracijaFilter){
    return `${address}${domain}${fixQuery(createQueryParams(query))}`;
}

function getPdfZahteva(idZahteva: number, sifraPp : string | null){
    return `${address}${domain}/stampa/${idZahteva}/${sifraPp}`;
}

function getPdfUslovProdaje(tipPotrosaca: string){
    return `${address}${domain}/stampa/usloviProdaje/${tipPotrosaca}`;
}

function getPdfRegistracija(tipDokumenta: string){
    return `${address}${domain}/stampa/${tipDokumenta}`;
}

export function downloadUslovProdajePdf(tipPotrosaca: string, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResourceNoHeader(getPdfUslovProdaje(tipPotrosaca), fileName);
}

export function downloadRegistracijaPdf(tipDokumenta: string, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResourceNoHeader(getPdfRegistracija(tipDokumenta), fileName);
}

export function downloadZahtevRegPdf(idZahteva: number, sifraPp : string | null, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResource(getPdfZahteva(idZahteva, sifraPp), fileName);
}

export function downloadZahtevRegPdfNoHeader(idZahteva: number, sifraPp : string | null, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResourceNoHeader(getPdfZahteva(idZahteva, sifraPp), fileName);
}

function getPdfZahtevaPrazan(){
    return `${address}${domain}/stampa/blankoZahtev`;
}

export function downloadPrazanZahtevPdf(): Promise<null|DigitResponse<any>>{
    return downloadPdfResourceNoHeader(getPdfZahtevaPrazan(), i18next.t("page-registracija.zahtevZaRegistracijuNazivFajla"));
}

export const registracijaApi = createApi ({
    reducerPath: "registracija",
    baseQuery: fetchBaseQuery({
        baseUrl:`${address}${domain}`,
        prepareHeaders: (headers) => {
            return setLangHeader(headers);
        }
        // prepareHeaders:(headers)=>{
        //     return updateHeaders(headers);
        // }
        // ovo je zakomentaisano zato sto na serverskoj strani 
    }),
    tagTypes: ["Registracija"],
    endpoints: (builder) =>({
        postRegistracijaPravnaLica: builder.mutation<DigitResponse<RegKorisnikaModel>, RegKorisnikaModelBody>({
            query(body){
                return {
                    url: 'kreiraj/zahtev',
                    method: "POST",
                    body 
                }
            },
            invalidatesTags: [{type: "Registracija", id: "ZAHTEV_ZA_REGISTRACIJU"}]
        }),
        postRegistracijaPravnaLicaAdmin: builder.mutation<DigitResponse<RegKorisnikaModel>, RegKorisnikaModelBody>({
            query(body){
                return {
                    url: 'kreiraj/zahtev/admin',
                    method: "POST",
                    body 
                }
            },
            invalidatesTags: [{type: "Registracija", id: "ZAHTEV_ZA_REGISTRACIJU"}]
        }),
        postSacuvajKorisnika: builder.mutation<DigitResponse<RegKorisnikaModel>, RegKorisnikaModelBody>({
            query(body){
                return {
                    url: 'kreiraj/korisnika',
                    method: "POST",
                    body 
                }
            },
            invalidatesTags: [{type: "Registracija", id: "KREIRANJE_KORISNIKA"}]
        }),
        postOdustani: builder.mutation<DigitResponse<RegKorisnikaModel>, RegKorisnikaModelBody>({
            query(body){
                return {
                    url: 'kreiraj/odustani',
                    method: "POST",
                    body 
                }
            },
            invalidatesTags: [{type: "Registracija", id: "ODUSTANI"}]
        }),
        postResetujZaboravljenuLozinku: builder.mutation<DigitResponse<string>, ZaboravljenaLozinkaBody>({
            query: (body : ZaboravljenaLozinkaBody) => {
                return {
                    url: `resetLozinke`,
                    method: "POST",
                    body
                }
            }
        }),
        deleteZahtevById: builder.mutation<DigitResponse<string>,  number | undefined>({
            query: (idZahteva) => {
                return {
                    url: `deleteZahtev/${idZahteva}`,
                    method: "DELETE",
                }
            }
        }),
 
        getNeobradjenjiZahZaRegistraciju: builder.query<DigitResponse<ZahtevRegistracije[]>, RegistracijaFilterRequest|void>({
            query: (filter?: RegistracijaFilterRequest)=> `neobradjeni${fixQuery(createQueryParams(filter))}`
        }),
        getZahtevById: builder.query<DigitResponse<ZahtevRegistracije>, RegistracijaFilter>({
            query: (filter)=>`get${fixQuery(createQueryParams(filter))}`
        }),
        getRandomPass: builder.query<DigitResponse<string[]>, RegistracijaFilter>({
            query: (filter)=>`getRandomPass${fixQuery(createQueryParams(filter))}`
        })
    })

})

export const {
    // dohvatanje podataka
    useGetNeobradjenjiZahZaRegistracijuQuery,
    useGetZahtevByIdQuery,
    useGetRandomPassQuery,
    // mutacije podataka
    usePostRegistracijaPravnaLicaMutation,
    usePostRegistracijaPravnaLicaAdminMutation,
    usePostSacuvajKorisnikaMutation,
    usePostOdustaniMutation,
    usePostResetujZaboravljenuLozinkuMutation,
    useDeleteZahtevByIdMutation
} = registracijaApi;
