import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, downloadPdfResource, fetchResource, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { DigitResponse } from "../util/digitInterfaces";
import { Faktura } from "../model/faktura/fakturaModel";

const address = config["razvoj"]  ? config["srbijagas.fakture.razvoj"] : config["srbijagas.fakture"];
const domain = "/fakture";

export function getFakturaBaseQuery(){
    return `${address}${domain}`
}

export interface FaktureFilter extends PaginationQuery {
    oPeriodNaziv?: string,
    mestaIsporuke?: number[],
    partneri?: number[];
    ukIznosDo?: number;
    ukIznosOd?: number;
}
/** Vraca Blob - Resource kada se posalje get request sa ovim url-om */
export function getFakturaPdf(idFakture: number) : string {
    return `${address}${domain}/pdf/${idFakture}`;
}

export const fakturaApi = createApi({
    reducerPath: "faktura",
    baseQuery: fetchBaseQuery({
        baseUrl: getFakturaBaseQuery(),
        prepareHeaders: (headers)=>{
            headers.append("Accept", "application/json")
            return updateHeaders(headers);
        }
    }),
    endpoints: (builder)=>({
        getFakture: builder.query<DigitResponse<PageableWithData<Faktura>>, FaktureFilter>({
            query: (filter)=>fixQuery(createQueryParams(filter))
        }),
        getFaktureObrPerioda: builder.query<DigitResponse<PageableWithData<Faktura>>, FaktureFilter>({
            query: (filter)=>`obracunskiPeriod${fixQuery(createQueryParams(filter))}`
        }),
        getObrPeriode: builder.query<DigitResponse<string[]>, void>({
            query: ()=>({
                url:`obracunskiPeriodi`,
            })
        }),
        getObracunskiPeriodSviNazivi: builder.query<DigitResponse<string[]>, void>({
            query: ()=>({
                url:`obracunskiPeriodiAll`,
            })
        }),
        getPosaljiFakturuMailom: builder.mutation<DigitResponse<string>, string|undefined>({
            query: (obrPeriod: string|undefined)=> {
                return {
                    url: `send/${obrPeriod}`,
                    method: "GET",
                }
            }
        }),
    })
})

export function fetchFakturaPdf(idFakture: number|undefined): Promise<string | null | DigitResponse<any>>{
    return fetchResource(idFakture ? getFakturaPdf(idFakture) : undefined);
}

export function downloadFakturaPdf(idFakture: number|undefined, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResource(idFakture ? getFakturaPdf(idFakture) : undefined, fileName);
}

export const {
    useGetFaktureQuery,
    useGetFaktureObrPeriodaQuery,
    useGetObrPeriodeQuery,
    useGetObracunskiPeriodSviNaziviQuery,
    useGetPosaljiFakturuMailomMutation
} = fakturaApi