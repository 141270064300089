import { AccountCircleOutlined } from "@mui/icons-material";
import { Button, ClickAwayListener, Fade, Paper, Popper, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearStatusLogin } from "../../../model/login/loginSlice";
import { useGetKorisnikQuery } from "../../../network/kportalaApiCalls";
import IconCollapse from "../../IconCollapse";
import SGLogin from "./SGLogin";
import SGLogout from "./SGLogout";

const stickyButton: SxProps<Theme> = {

}

function SGAuth(props: WithTranslation){

    const dispatch = useDispatch();
    const korisnik = useGetKorisnikQuery();

    const {t} = props;

    const getKorisnik = ()=>{
        return korisnik.data?.content
    }

    const [anchorEl, setAnchorEl] = useState<any|null>(null);
    const [openPoper, setOpenPoper] = useState(false);

    return <>
        <Button onClick={(event)=>{
            event.stopPropagation()            
            setAnchorEl(event.currentTarget)
            setOpenPoper(!openPoper)
            
        }} startIcon={
            <AccountCircleOutlined sx={{color: (theme)=>theme.palette.secondary.dark}} fontSize="large"/>
        } endIcon={
            <IconCollapse componentIsOpened={openPoper}/>
        } size="large" sx={stickyButton}
        >
            {getKorisnik() ? getKorisnik()?.naziv : t('common.moj-srbijagas')}
        </Button>
        <ClickAwayListener onClickAway={()=>{
                openPoper && setOpenPoper(false);
                dispatch(clearStatusLogin());
        }}>
            <Popper open={openPoper} anchorEl={anchorEl} transition placement={"bottom-end"} style={{ zIndex: 150}}>
                {({TransitionProps})=>(
                    <Fade {...TransitionProps}>
                        <Paper component="div" elevation={1} sx={{backgroundColor: "whitesmoke", opacity:1, position:"relative", zIndex: 160}}>
                            {getKorisnik() ? <SGLogout onSelectOption={()=>setOpenPoper(false)}/> : <SGLogin closePopup={()=>setOpenPoper(false)}/>}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </ClickAwayListener>
    </>;
}

export default withTranslation()(SGAuth);