import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from "@mui/material";
import { useEffect } from "react";

export interface CheckboxPropTypes extends CheckboxProps {
    readOnly?: boolean;
    label?: string|JSX.Element;
    labelProps?: FormControlLabelProps;
    // visak iz form data
    helperText?: string;
    error?: boolean;
}

export default function Checkbox(props: CheckboxPropTypes){

    const { readOnly, value, label, labelProps, error, helperText, required, ...otherProps } = props;

    useEffect(()=>{},[props]);

    const field = (
        <MuiCheckbox
            checked={!!value}
            // must have a role set so that the change event in useForm knows what type this field is
            inputProps={{ 'role': "checkbox" }}
            {...otherProps}
        />
    );

    if(props.label){
        return (
            <FormControlLabel label={props.label} control={field} {...props.labelProps}/>
        );
    }
    return field;
}