import { SxProps, Theme } from "@mui/material";

type DSxStyle = SxProps<Theme> | undefined;

type IoFieldStylesType = {
    filterField: DSxStyle;
    secondaryColor: DSxStyle;
}

export default function ioFieldStyles(): IoFieldStylesType{
    return {
        secondaryColor: (theme)=>({
            color: theme.palette.text.secondary,
        }),
        filterField: (theme)=>({
            "& *[class*='MuiSelect-select']":{
                paddingTop: "10px",
                paddingBottom: "10px",
            },
            "& label[data-shrink='false']": {
                fontSize: "0.9em"
            },
            "& label[data-shrink='true']": {
                fontSize: "1em"
            },
            "& input": {
                fontSize: "0.9em"
            },
            "& *[class*='MuiAutocomplete-root']": {
                padding: "0px !important",
            },
            "& *[class*='MuiOutlinedInput']":{
                borderWidth: `1px`,
                borderColor: theme.palette.grey[500]
            }
        })
    }
}