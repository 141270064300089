import { Box, Container } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import NovostiVertical from "../../../pages/novosti/NovostiVertical";
import SubMenuVerticalNav, { SubMenuVerticalNavProps } from "../menu/SubMenuVerticalNav";
import TemplateHeader, { TemplateHeaderProps } from "./TemplateHeader";

interface TemplateContentProps{
    leftNavProps?: SubMenuVerticalNavProps;
    headerProps?: TemplateHeaderProps;
}

/**
 * 
 * @param props -children komponente idu nakon main contenta
 */
export default function TemplateContent(props: PropsWithChildren<TemplateContentProps>){

    useEffect(()=>{

    }, [props])

    return (
        <Container maxWidth={false}>
            {/* TODO: Zakomentarisano zato sto se ne prikazuje svuda i nekako lepse izgleda bez toga */}
            {/* { props.headerProps && <TemplateHeader {...props.headerProps}/> } */}

            <Container component={"div"} maxWidth={false} sx={(theme)=>({ 
                marginTop:theme.spacing(2), minHeight: "70vh", display: "flex", flexDirection: "row",
                justifyContent: "center"
            })}>
                
                {props.leftNavProps &&
                    <Box component={"div"} sx={{display: "inline-flex", flexDirection: "column", flexGrow: 1,
                        flex: 1, minWidth: "20rem", maxWidth: "30rem",
                        paddingLeft: "0px !important", marginLeft: "0px !important"
                    }} 
                        id="leftNav"
                    >
                        <SubMenuVerticalNav {...props.leftNavProps}/>
                        <NovostiVertical/>
                    </Box> 
                }

                <Box component={"div"} id="content" sx={{display: "inline-flex", flex: 3, flexDirection: "row", flexGrow: 3, justifyContent:"space-evenly",
                    maxWidth: "70rem"
                }}>
                    {props.children}
                </Box> 
            </Container>
        </Container>
    );

}