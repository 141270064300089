import { createSelector } from "@reduxjs/toolkit";
import { fakturaApi } from "../../network/fakturaApiCalls";

export const selectObrPeriodiResult = fakturaApi.endpoints.getObrPeriode.select()

export const selectObracunskiPeriodiSviNaziviResult = fakturaApi.endpoints.getObracunskiPeriodSviNazivi.select()

export const selectObrPeriodiNazivi = createSelector(
    selectObrPeriodiResult,
    result => result.data?.content
)

export const selectObracunskiPeriodiSviNazivi = createSelector(
    selectObracunskiPeriodiSviNaziviResult,
    result => result.data?.content
)
