import { Api, createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { updateHeaders } from "./apiCalls";
import config from "./config.json";
import { DigitResponse } from "../util/digitInterfaces";
import { PageableWithData } from "../util/hooks/usePagination";
import { AdminiFilter, Admini, IzmenaAdmina } from "../pages/registracijaAdmina/components/pregledAdminaSchema";

const address = config["razvoj"] ? config["srbijagas.registracijaAdmina.razvoj"] : config["srbijagas.registracijaAdmina"];
const domain = "/registracijaAdmina";

const TAG_ADMINI = "Admini" as const;

export const registracijaAdminaApi = createApi({
    reducerPath: "registracijaAdmina",
    baseQuery: fetchBaseQuery({
        baseUrl: `${address}${domain}`,
        prepareHeaders: (headers) => {
            headers.append("Accept", "application/json")
            return updateHeaders(headers);
        }
    }),
    tagTypes: [TAG_ADMINI],
    endpoints: (builder) => ({
        getAdmine: builder.query<DigitResponse<PageableWithData<Admini>>, AdminiFilter | void>({
            query: (body?: AdminiFilter) => ({
                url: "/pretraga",
                method: "POST",
                body
            }),
            providesTags: (result) => result?.content
                ? [
                    { type: TAG_ADMINI, id: "LIST" },
                    ...(result.content?.content?.map((i)=> ({type: TAG_ADMINI, id: i.id})) || [])
                ]
                : [{ type: TAG_ADMINI, id: "LIST" }],
        }),
        unosAdmina: builder.mutation<DigitResponse<string>, Partial<IzmenaAdmina>>({
            query: (body: Partial<IzmenaAdmina>)=>({
                url: "/unos",
                method: "POST",
                body
            }),
            invalidatesTags: [TAG_ADMINI]
        }),
        izmenaAdmina: builder.mutation<DigitResponse<string>, Partial<IzmenaAdmina>>({
            query: (body: Partial<IzmenaAdmina>)=>({
                url: "/izmena",
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: TAG_ADMINI, id: arg.id }]
        }),
        brisanjeAdmina: builder.mutation<DigitResponse<string>, number[]>({
            query: (ids: number[])=>({
                url: `/deaktiviraj`,
                method: "POST",
                body: {ids}
            }),
            invalidatesTags: (result, error, arg) => arg.map(i=>({ type: TAG_ADMINI, id: i })) 
        }),
        aktiviranjeNalogaAdmina: builder.mutation<DigitResponse<string>, number[]>({
            query: (ids: number[])=>({
                url: `/aktiviraj`,
                method: "POST",
                body: {ids}
            }),
            invalidatesTags: (result, error, arg) => arg.map(i=>({ type: TAG_ADMINI, id: i })) 
        }),
        resetLozinkeAdmina: builder.mutation<DigitResponse<string>, number[]>({
            query: (idsKorisnika: number[]) =>({
                url: `/resetLozinki`,
                method: "POST",
                body: {ids: idsKorisnika }
            })
        })
    })
})

export const {
    useGetAdmineQuery,
    useBrisanjeAdminaMutation,
    useIzmenaAdminaMutation,
    useResetLozinkeAdminaMutation,
    useUnosAdminaMutation,
    useAktiviranjeNalogaAdminaMutation
} = registracijaAdminaApi;