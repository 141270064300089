import { Collapse, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemIconProps, ListItemText, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useShowMenuItem from "../../../util/hooks/useShowMenuItem";
import IconCollapse from "../../IconCollapse";
import useKorisnik from "../auth/useKorisnik";
import { MenuItem } from "./Meni";

interface DListItemProps extends ListItemButtonProps {
    isActive?: boolean; 
}

const DListItem = styled(ListItemButton, {
    shouldForwardProp: (prop)=>prop !== "isActive",
})<DListItemProps>(({isActive, theme}) =>({
    width: "100%",
    fontSize: "0.9rem !important",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    transition: "background-color 0.2s ease-in-out",
    "&[class*='MuiListItemButton-root']:hover":{
        backgroundColor: theme.palette.grey.A100,
    },
    border: `0.5px solid ${theme.palette.grey.A400}`,
    "&:not(last-child)":{
        borderBottom: "none !important"
    },
    "&:last-child":{
        borderBottom: `0.5px solid ${theme.palette.grey.A400}  !important`
    },  
    ...(isActive &&{
        color: theme.palette.primary.main,
        borderLeft: `5px solid ${theme.palette.secondary.main} !important`,
        "&:hover" :{
            color: theme.palette.primary.main
        }
    })
}));

interface DListItemIconProps extends ListItemIconProps {
    isOpen?: boolean
}

const DListItemIcon = styled(ListItemIcon, {
    shouldForwardProp: (prop)=>prop!=="isOpen",
})<DListItemIconProps>(({isOpen, theme})=>({
    "& svg":{
        color: theme.palette.secondary.main,
        transition: "transform 0.2s ease-in-out",
    },
    ...(!isOpen && {
        "& svg":{
            transform: "rotate(180deg)"
        }
    })
}));

export type SubMenuVerticalNavProps = {
    submenu: MenuItem[];
    parentPath?: string;
}

export default function SubMenuVerticalNav(props: SubMenuVerticalNavProps){

    const match = useLocation();
    const korisnik = useKorisnik();

    const showMenus = useShowMenuItem({
        menuItems: props.submenu.map(item=>({[item.id]: item})).reduce((a, b)=>({...a,...b}), {}), 
        korisnik: korisnik
    })

    const [openedSubmenus, setOpenedSubmenus]=useState<string[]>([]);

    const toggleSubmenu = ( id: string )=>{
        if(openedSubmenus.find(i=>i===id)){
            setOpenedSubmenus(openedSubmenus.filter(i=>i!==id));
        }
        else{
            setOpenedSubmenus([...openedSubmenus, id]);
        }
    }

    useEffect(()=>{
    }, [props.submenu, props.parentPath, korisnik]);
    
    const checkOpenStatus = (item: MenuItem)=>{
        return match.pathname===item.to;
    }

    const hasActiveSubmenu = (item: MenuItem)=>{
        if(item.items)
            for(let i = 0; i < item.items?.length || 0; i++){
                if(item.items[i].to === match.pathname){
                    return true;
                }
            }

        return false
    }

    const submenuIsOpened = (item: MenuItem)=>{
        return openedSubmenus.find(i=>item.id === i) !== undefined;
    }

    return (
        <List aria-label="options" sx={{width: "100%"}} disablePadding>
            {
                props.submenu.map((option: MenuItem)=>{                       
                    if(!showMenus[option.id]){
                        return null;
                    }
                    return (
                        <Fragment key={`lvm_option${option.id}`}>
                            <NavLink
                                to={`${props.parentPath ? props.parentPath: ""}${option.to}`}
                                key={"hNav"+option.id}
                                exact
                            >
                                <DListItem key={"li"+option.id} isActive={checkOpenStatus(option)}>

                                    {option.items && option.items.length > 0 &&
                                        <DListItemIcon sx={{marginRight: (theme)=>theme.spacing(1)}}>
                                            <IconCollapse
                                                componentIsOpened={submenuIsOpened(option)}
                                                onClick={(event)=>{
                                                    toggleSubmenu(option.id); 
                                                    // da se prekine propagacija koja rutira na meni, vec samo da se otvori
                                                    event.preventDefault();
                                                }}
                                                fontSize="small"
                                            />
                                        </DListItemIcon>
                                    }
                                    {
                                        option.icon && <ListItemIcon >{option.icon}</ListItemIcon>
                                    }
                                    <ListItemText primary={option.title}/>
                                </DListItem>
                            </NavLink>
                            { option.items && option.items.length > 0 &&
                                <Collapse in={submenuIsOpened(option) || hasActiveSubmenu(option)} unmountOnExit>
                                    <List sx={{width: "100%"}} disablePadding>
                                        {
                                            option.items?.map((subitem)=>{
                                                return (
                                                    <NavLink to={{
                                                        pathname: subitem.to,
                                                        state: subitem.state || null
                                                    }} key={"li"+subitem.id} exact>
                                                        <DListItem isActive={checkOpenStatus(subitem)}>
                                                            {
                                                                subitem.icon && <ListItemIcon >{subitem.icon}</ListItemIcon>
                                                            }
                                                            <ListItemText sx={{paddingLeft: (theme)=>theme.spacing(2)}} primary={subitem.title}/>
                                                        </DListItem>
                                                    </NavLink>
                                                );
                                            })
                                        }
                                    </List>
                                </Collapse>
                            }
                        </Fragment>
                    )
                })
            }
        </List>
    )
}