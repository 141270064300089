import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, updateHeaders } from "./apiCalls";
import config from "./config.json";

/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { MestaIsporuke, Mesto } from "../model/mestaIsporuke/mestaIsporukeModel";

const address = config["razvoj"]  ? config["srbijagas.mestaIsporuke.razvoj"] : config["srbijagas.mestaIsporuke"];
const domain = "/mestaIsporuke";

export interface MestaIsporukeFilter extends PaginationQuery{
    partneri?: number[]; // i za sifraPp i za sifra2
    mestaId?: number[];
    nazivLike?: string;
    adresaLike?: string;

    sifraMesta?: string; // sifra iz Mesto
    kategorijaPotrosnje?: string;
    grupaPotrosnje?: string;
    vrstaSnabdevanja?: string;
    radnaJedinica?: string;

    pttLike?: string;    
}

export function getMestaIsporukeBaseQuery(){
    return  `${address}${domain}`
}

export const mestaIsporukeApi = createApi({
    reducerPath: "mestaIsporuke",
    baseQuery: fetchBaseQuery({
        baseUrl: getMestaIsporukeBaseQuery(),
        prepareHeaders:(headers)=>{
            return updateHeaders(headers);
        }
    }),
    endpoints: (builder)=>({
        getMestaIsporuke: builder.query<DigitResponse<PageableWithData<MestaIsporuke>>, MestaIsporukeFilter>({
            query: (filter?: MestaIsporukeFilter)=>`${fixQuery(createQueryParams(filter))}`
        }),
        getMestoIsporuke: builder.query<DigitResponse<MestaIsporuke>, number>({
            query: (miId: number)=>`${miId}`
        }),
        getKategorijePotrosnje: builder.query<DigitResponse<string[]>, void>({
            query: ()=> `/kategorije`
        }),
        getMesta: builder.query<DigitResponse<Mesto[]>, void>({
            query: ()=> `/mesta`
        }),
        getGrupePotrosnje: builder.query<DigitResponse<string[]>, void>({
            query: ()=> `/grupePotrosnje`
        }),
        getVrsteSnabdevanja: builder.query<DigitResponse<string[]>, void>({
            query: ()=> `/vrsteSnabdevanja`
        }),
        getRadneJedinice: builder.query<DigitResponse<string[]>, void>({
            query: ()=> `/radneJedinice`
        })
    })
})

export const {useGetMestaIsporukeQuery, useGetMestoIsporukeQuery,
    useGetGrupePotrosnjeQuery,
    useGetKategorijePotrosnjeQuery,
    useGetMestaQuery,
    useGetRadneJediniceQuery,
    useGetVrsteSnabdevanjaQuery
} = mestaIsporukeApi