import i18next from "i18next"
import { lazy } from "react"
import { SGAS_ROLE } from "../../../../model/role"
import { MenuItem } from "../Meni"

const PregledRacuna = lazy(()=>import('../../../../pages/pregled/PregledRacuna'))
const FinansijskaKartica = lazy(()=>import('../../../../pages/pregled/FinansijskaKartica'))
const PregledStatusaRacuna = lazy(()=>import('../../../../pages/pregled/PregledStatusaRacuna'))
const PregledUtuzenja = lazy(()=>import('../../../../pages/pregled/PregledUtuzenja'))
const ElektronskaDostavaRacuna = lazy(()=>import("../../../../pages/elektronskaDostava/ElektronskaDostavaRacuna"))
const PregledIOP = lazy(()=>import("../../../../pages/pregled/PregledIOP"))
const PregledPojedinacnogRacuna = lazy(()=>import("../../../../pages/pregled/components/PregledPojedinacnogRacuna"));

type PregledMenuType = {
    pregledRacuna: MenuItem;
    finansijskaKartica: MenuItem;
    pregledStatusaRacuna: MenuItem;
    pregledUtuzenja: MenuItem;
    elektronskaDostavaRacuna: MenuItem;
    pregledIOP: MenuItem;
}

export function getMenuSubItemPregled(title: string): MenuItem {
    let idLike = title.split(' ').join('-')
    return {
        to: `/pregled/pregledRacuna/${encodeURI(idLike)}`,
        id: `pojedinacna${idLike}`,
        title: title ? `${i18next.t(`months.${+title.split(" ")[0] - 1}`)} ${title.split(" ")[1]}` : title,
        rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
        component: PregledPojedinacnogRacuna,
        state: {obracunskiPeriod: title}
    }
}

export default function pregledMenu(): PregledMenuType {
    return {
        pregledRacuna: {
            to: "/pregled/pregledRacuna",
            id: "pregledRacuna",
            title: i18next.t("page-pregled.pregled-racuna.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PregledRacuna
        },
        finansijskaKartica: {
            to: "/pregled/finansijskaKartica",
            id: "finansijskaKartica",
            title: i18next.t("page-pregled.fin-kartica.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: FinansijskaKartica
        },
        pregledStatusaRacuna: {
            to: "/pregled/pregledStatusaRacuna",
            id: "pregledStatusaRacuna",
            title: i18next.t("page-pregled.status-racuna.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PregledStatusaRacuna
        },
        pregledUtuzenja: {
            to: "/pregled/pregledUtuzenja",
            id: "pregledUtuzenja",
            title: i18next.t("page-pregled.utuzenja.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PregledUtuzenja
        },
        elektronskaDostavaRacuna: {
            to: "/pregled/elektronskaDostava",
            id: "elektronskaDostavaRacuna",
            title: i18next.t("page-pregled.elektronska-dostava.label"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: ElektronskaDostavaRacuna
        },
        pregledIOP: {
            to: "/pregled/pregledIOP",
            id: "pregledIOP",
            title: i18next.t("page-pregled.iop.label"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PregledIOP,
            hiddenForFizicko: true
        }
    }
}