import i18next from "i18next";
import { lazy } from "react";
import { SGAS_ROLE } from "../../../../model/role";
import { MenuItem } from "../Meni";

const GenerisanjeRacuna = lazy(()=>import( "../../../../pages/administracija/generisanjeRacuna/GenerisanjeRacuna"));
// const GenIzvestajaOperacija = lazy(()=>import( "../../../../pages/administracija/genIzvestajaOperacija/GenIzvestajaOperacija"));
const PregledZahtevaRegistracije = lazy(()=>import("../../../../pages/administracija/korisniciSistema/registracija/PregledZahtevaRegistracije"));
const PregledAktivnosti = lazy(()=>import( "../../../../pages/administracija/pregledAktivnosti/PregledAktivnosti"));
const AdminUnosNovogKorisnika = lazy(()=>import( "../../../../pages/administracija/korisniciSistema/registracija/AdminUnosNovogKorisnika"));
const KorisniciSistema = lazy(()=>import( "../../../../pages/administracija/korisniciSistema/KorisniciSistema"));
const RegistracijaAdminaPage = lazy(()=>import("../../../../pages/registracijaAdmina/RegistracijaAdminaPage"));

type AdministracijaMenuType = {
    genRacuna: MenuItem;
    korisniciSistema: MenuItem;
    pregledAktivnosti: MenuItem;
    // genIzvestajaOperacija: MenuItem;
    unosNovogKorisnika: MenuItem;
    pregledZahtevaZaReg: MenuItem;
    registracijaAdmina: MenuItem;
}

export default function administracijaMenu(): AdministracijaMenuType {
    return {
        genRacuna: {
            to: "/administracija/genRacuna",
            id:"genRacuna",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("gen-racuna.navTitle"),
            component: GenerisanjeRacuna
        },
        korisniciSistema: {
            to: "/administracija/korisniciSistema",
            id:"korisniciSistema",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("korisnici-sistema.navTitle"),
            component: KorisniciSistema
        },
        unosNovogKorisnika:{
            to: "/administracija/unosNovogKor",
            id:"kreiranjeZahteva",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("korisnici-sistema.noviZahtevZaRegistraciju"),
            component: AdminUnosNovogKorisnika,
        },
        pregledZahtevaZaReg:{
            to: "/administracija/pregledZahtevaZaReg",
            id:"pregledZahtevaZaReg",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("korisnici-sistema.obradaRegistracije"),
            component: PregledZahtevaRegistracije,
        },
        pregledAktivnosti: {
            to: "/administracija/pregledAktivnosti",
            id:"pregledAktivnosti",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("pregled-aktivnosti.navTitle"),
            component: PregledAktivnosti
        },
        registracijaAdmina: {
            to: "/administracija/admini",
            id: "registracijaAdmina",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            title: i18next.t("title", {ns: "registracija-admina"}),
            component: RegistracijaAdminaPage
        },
        // genIzvestajaOperacija: {
        //     to: "/administracija/genIzvestajaOperacija",
        //     id:"genIzvestajaOperacija",
        //     rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
        //     title: i18next.t("gen-operacija.navTitle"),
        //     component: GenIzvestajaOperacija
        // },
    }
}