import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, fetchResource, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IstorijaNaloga, PoslovniPartner, StatusNaloga } from "../model/poslovniPartner/poslovniPartnerModel";
import { createSelector } from "@reduxjs/toolkit";

const address = config["razvoj"]  ? config["srbijagas.ppartner.razvoj"] : config["srbijagas.ppartner"];
const domain = "/ppartner";

export interface PartnerFilter extends PaginationQuery{
    partneri?: string[];
    jmbg?: string;
    sifraIliNazivLike?: string;

    tipLica?: string;
    pibLike?: string;
    mbrLike?: string;
    emailLike?: string;
    nadlezniRadnikLike?: string;
    nadleznaOj?: string;
    pttLike?: string;
}

export interface AdresaPoslovnogPartnera {
    sedisteAdresa?: string;
    pak?: string;
    grad?: string;
    ptt?: string;
}

export function getPromeniProfilnuSlikuBody(props: {file: File; filename: string; }) 
: FormData {
    let form = new FormData();
        form.append("file", props.file, props.filename);
    return form;
}

export type PromeniProfilnuSlikuBody = ReturnType<typeof getPromeniProfilnuSlikuBody>;

/** Dobija se Blob - resource */
export function getPartnerSlika(){
    return `${address}${domain}/image`
}
export default function getPartnerBaseQuery(){
    return `${address}${domain}`
}

export const partnerApi = createApi({
    reducerPath: "partner",
    baseQuery: fetchBaseQuery({
        baseUrl: getPartnerBaseQuery(),
        prepareHeaders: (headers, { getState }) => {
            // iz primera
            // const token = (getState() as RootState).auth.token;
            // // If we have a token set in state, let's assume that we should be passing it.
            // if (token) {
            //   headers.set('authorization', `Bearer ${token}`);
            // }
            // return headers;
            headers.append("Accept", "application/json")
            return updateHeaders(headers);
          },
    }),
    endpoints: (builder)=>({
        getPartnerInfo: builder.query<DigitResponse<PoslovniPartner>, void>({
            query: ()=>`info`,

        }),
        getPartnere: builder.query<DigitResponse<PageableWithData<PoslovniPartner>>, PartnerFilter>({
            query: (filter?: PartnerFilter)=>`filter${fixQuery(createQueryParams(filter))}`
        }),
        getStatuseNaloga: builder.query<DigitResponse<StatusNaloga[]>, void>({
            query: ()=> `statusiNaloga`
        }),
        getNadlezneOjZaPartnere: builder.query<DigitResponse<string[]>, void>({
            query: ()=> `nadlezneOj`
        }),
        getIstorijaNaloga: builder.query<DigitResponse<IstorijaNaloga[]>, number>({
            query: (idKorisnika: number)=> `istorijaNaloga/${idKorisnika}`
        }),
        postSacuvajAdresu: builder.mutation<DigitResponse<string>, AdresaPoslovnogPartnera>({
            query: (body: AdresaPoslovnogPartnera) => {
                return {
                    url: "adresa",
                    method: "POST",
                    body
                }
            }
        }),
        postSacuvajProfilnuSliku: builder.mutation<DigitResponse<string>, PromeniProfilnuSlikuBody>({
            query(body){
                return {
                    url: 'image/promeni',
                    method: "POST",
                    body
                }
            },
        }),
        deleteObrisiProfilnuSliku: builder.mutation<DigitResponse<string>, void>({
            query(){
                return {
                    url: 'image/obrisi',
                    method: "DELETE"
                }
            },
        }),
    })
})

export const selectPartnerInfoResult = partnerApi.endpoints.getPartnerInfo.select()

export const selectInfoPartnera = createSelector(
    selectPartnerInfoResult,
    infoResult=> infoResult.data?.content
)

export function fetchPartnerSlika(){
    return fetchResource(getPartnerSlika());
}

export const { 
    useGetPartnerInfoQuery,
    useGetPartnereQuery,
    useGetIstorijaNalogaQuery,
    useGetStatuseNalogaQuery,
    useGetNadlezneOjZaPartnereQuery,
    usePostSacuvajAdresuMutation,
    usePostSacuvajProfilnuSlikuMutation,
    useDeleteObrisiProfilnuSlikuMutation
} = partnerApi