import { CircularProgress, Collapse, Modal, Paper, Theme, SxProps, Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { CSSProperties, PropsWithChildren, useEffect } from "react";

const paperSx = (theme: Theme): SystemStyleObject<Theme> => ({
    position: "relative",
    margin: "auto",
    marginTop: theme.spacing(2),
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center"
})

const progressSx = (theme: Theme) => ({
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
})

interface DCircularProgressProps {
    hidden: boolean,
    /** za prikaz modala preko cele stranice, zadimljen ostatak ekrana */
    fullscreen?: boolean,
    /** ako je modal u pitanju, onda disableuje zatvaranje komponente dok se ne zavrsi operacija  */
    disableClose?: boolean,
    /** podrazumevano se samo sakrije prikaz, ovako se pri zatvaranju modala i
     * on unmount pozove neka zavrsna funkcija
     */
    onClose?: () => void,
    progressColor?: string;
    simple?: boolean;
}
/**
 * 
 * @param props with children koja idu u deo za porukicu sa desne strane indikatora progresa
 */
export default function DCircularProgress (props: PropsWithChildren<DCircularProgressProps>) {

    useEffect(() => {
        return () => {
            if (props.onClose) {
                props.onClose();
            }
        }
    }, [props]);

    const progressStyle: SxProps<Theme> = {
        ...progressSx, position: "relative", ...(props.progressColor ? { color: props.progressColor } : {})
    }

    const render3circles = (styleOverride?: CSSProperties) => {
        return (
            <>
                <CircularProgress size={"1rem"} sx={{ ...progressStyle, ...styleOverride, marginRight: "-1.5rem", marginBottom: "1rem" }} />
                <CircularProgress size={"2rem"} sx={{ ...progressStyle, ...styleOverride, marginBottom: "0.5rem", animationDelay: "0.2s", animationDuration: "2s" }} />
                <CircularProgress size={"3rem"} sx={{ ...progressStyle, ...styleOverride, marginLeft: "-2.5rem", animationDelay: "1s", animationDuration: "3s" }} />
            </>
        );
    }

    if (props.fullscreen) {
        return (
            <Modal
                component={"div"}
                open={!props.hidden}
                onClose={(event: object, reason) => {
                    props.onClose && props.onClose();
                }}
                disableEscapeKeyDown

            // disableBackdropClick={props.disableClose === true}
            >
                {
                    props.simple ?
                        <Box sx={{
                            position: "absolute",
                            transform: 'translate(-50%, -50%) scale(2)',
                            left: "50%", top: "50%"
                        }}>
                            {render3circles({ color: "white" })}
                        </Box>
                        :
                        <Paper sx={paperSx}>
                            <CircularProgress sx={progressSx} />
                            <div>{props.children}</div>
                        </Paper>
                }
            </Modal>
        )
    }

    return (
        <Collapse in={!props.hidden}>
            <div style={{ textAlign: "center" }} >
                <div style={{ display: "flex", alignItems: "center", width: "fit-content", margin: "auto" }}>
                    <div style={{ display: "inline-block" }}>
                        {render3circles()}
                    </div>
                    <div style={{ display: "inline-block", height: "100%" }}>{props.children}</div>
                </div>
            </div>
        </Collapse>
    );
}