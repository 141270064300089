import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { batch } from "react-redux";
import { postLogin } from "../../network/auth";
import { fakturaApi } from "../../network/fakturaApiCalls";
import { korisnikApi } from "../../network/kportalaApiCalls";
import { RootState } from "../../store";
import { FetchedData, initObradaZahteva, KeycloakRespose, ObradaZahteva } from "../../util/digitInterfaces";

interface LoginResponse{
    hasError: boolean;
    msg?: string;
    data?: KeycloakRespose;
}

interface LoginState {
    status: ObradaZahteva;
    response: KeycloakRespose | undefined;
}

const initialState : LoginState = {
    status: initObradaZahteva(),
    response: undefined
}

const loginSlice = createSlice({
    name:"login",
    initialState: initialState,
    reducers: {
        poslatZahtevZaLogin: (state)=>{
            state.status.uToku=true;
        },
        dobijenOdgovor: (state, action: PayloadAction<FetchedData>)=>{
            state.status.uToku = false;
            state.status.uspesno = action.payload.successful;
            if(action.payload.successful){
                state.response = action.payload.response;
            }
            else{
                state.status.poruka = action.payload.response;
            }
        },
        poslatZahtevZaLogout:(state)=>{
            state.response = undefined;
            state.status.uToku=false;
            state.status.poruka=undefined;
            state.status.uspesno=undefined;
        },
        clearStatus:(state)=>{
            state.status = initObradaZahteva();
        }
    }
})

const {
    poslatZahtevZaLogin, dobijenOdgovor, poslatZahtevZaLogout
} = loginSlice.actions;

export const {clearStatus: clearStatusLogin}=loginSlice.actions

export const selectLoginStatus = (root: RootState)=>root.login.status;

export function doLogin(data: any, afterLoginFunction: Function){
    return async (dispatch: Function)=>{
        dispatch(poslatZahtevZaLogin());
        postLogin(data).then((response: Response)=>{
            if(response.ok){
                response.text().then((res: any)=>{
                    afterLoginFunction();
                    dispatch(afterLogin({hasError:false, data: res}));
                })
            }
            else{
                response.json().then((res: any)=>{
                    dispatch(afterLogin({hasError:true, msg: res.error || `Došlo je do greške, pokušajte kasnije`}));
                })
            }
        }).catch((reason: any)=>{
            dispatch(afterLogin({hasError: true, msg:`Došlo je do greške, pokušajte kasnije`}));
        })
    }
}

function afterLogin(test: LoginResponse){
    return async (dispatch: Function)=>{
        if(!test.hasError){
            sessionStorage.setItem("test", JSON.stringify(test.data));
        }
        dispatch(dobijenOdgovor({successful: !test.hasError, response: test.hasError ? test.msg : test.data}))
    }
}

/**
 * !!! IMPORTANT !!!
 * Ciscenje bitnih podataka.
 */
export function postLogout(){
    return async (dispatch: Function)=>{
        batch(()=>{
            dispatch(poslatZahtevZaLogout());
        });
        fakturaApi.util.resetApiState();
        // TODO: reset state-a
        sessionStorage.removeItem("test");
        window.location.reload();
    }
}

export function promeniLozinku(data: any){
    return async (dispatch: Function) =>{
        // TODO: zameni sa doApiCall i prosledi podatke
    }
}

export default loginSlice.reducer;
