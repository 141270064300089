import { useEffect, useState } from "react";
import { MenuItem } from "../../components/mainComponents/menu/Meni";
import Korisnik from "../../model/korisnikPortala/korisnikPortalaModel";
import { SGAS_ROLE } from "../../model/role";
import { TIP_FIZICKO_LICE, TIP_PRAVNO_LICE } from "../konstante";

export default function useShowMenuItem(props:{
    menuItems: {[menuItemId: string]: MenuItem};
    korisnik: Korisnik|null|undefined;
}){
    const shouldShow = (item: MenuItem, korisnik: Korisnik|undefined|null) => {
        if(korisnik?.jeAdministrator && item.hiddenForAdmin){
          return false;
        }
        if (item.rolesNeeded?.includes(SGAS_ROLE.ROLE_ADMIN)) {
          return korisnik?.jeAdministrator;
        }
        if(korisnik?.tipLica === TIP_PRAVNO_LICE && item.hiddenForPravno){
          return false
        }
        if(korisnik?.tipLica === TIP_FIZICKO_LICE && item.hiddenForFizicko){
          return false
        }
        if (item.rolesNeeded?.includes(SGAS_ROLE.ROLE_AUTH_USER) && korisnik !== undefined) {
          return true;
        }
        if (item.rolesNeeded === undefined || item.rolesNeeded.length === 0) {
          return true;
        }
        return false;
    }
    
    const [show, setShow] = useState<{[menuItemId: string]: boolean}>(
        Object.keys(props.menuItems).map(key=>({[key]: false})).reduce((a,b)=>({...a,...b}), {})
    )

    useEffect(()=>{
        let updates = Object.keys(props.menuItems).map(key=>{
            return {
                [key]: shouldShow(props.menuItems[key], props.korisnik) || false
            }
        }).reduce((a,b)=>({...a,...b}), {})
        // ako se ne proveri da li je isto udje u petlju osvezavanja.
        if(Object.keys(updates).some(key=>show[key] !== updates[key])){
            setShow({
                ...show,
                ...updates
            })
        }
    },[props])


    return {
        ...show
    }
}