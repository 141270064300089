import { Button, ButtonProps, darken, styled } from "@mui/material";
import { MouseEvent, PropsWithChildren, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useShowMenuItem from "../../../util/hooks/useShowMenuItem";
import useKorisnik from "../auth/useKorisnik";
import { MenuItem } from "./Meni";

interface StyledNavButtonProps extends ButtonProps {
    isActive?: boolean;
    hasAnchor?: boolean;
}
const StyledNavButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "isActive" && prop !== "hasAnchor",
})<StyledNavButtonProps>(({ isActive, hasAnchor, theme }) => ({
    textDecoration: "none",
    "& *": {
        color: '#fff',
        textTransform: "unset",
    },
    borderRadius: "0",
    "&:hover, &.MuiIconButton-root": {
        transitionTimingFunction: 'ease-in',
        transitionDuration: "100ms",
    },
    '&:hover': {
        transitionTimingFunction: 'ease-in',
        transitionDuration: "100ms",
        background: darken('#00000055', 0.8),
    },
    "& button:focus": {
        backgroundColor: darken('#00000055', 0.8),
    },
    "& *[class*='MuiSvgIcon-root']": {
        marginRight: "0.35em"
    },
    "& button :hover": {
        backgroundColor: darken('#00000055', 0.8),
    },
    padding: "5px 15px",
    ...((hasAnchor || isActive) && {
        background: darken('#00000055', 0.8) + " !important"
    }),
}))

function HorizontalNavLink (props: { item: MenuItem, onCloseParentCallback?: Function, onClick?: () => void }) {

    const { item, onCloseParentCallback } = props;
    const hasItems = item.items && item.items.length !== 0;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const matcher = useLocation();

    const korisnik = useKorisnik();

    const showMenus = useShowMenuItem({menuItems: {"temp": item}, korisnik: korisnik})

    const handleSetAnchorEl = (el: HTMLElement | null) => {
        if (hasItems) {
            setAnchorEl(el);
        }
        else {
            if (onCloseParentCallback)
                onCloseParentCallback();
        }
    }

    const getNavButton = () => {
        return (
            <StyledNavButton onClick={(event: MouseEvent<HTMLElement>) => {
                // handleSetAnchorEl(event.currentTarget)
                props.onClick && props.onClick()
            }}
                isActive={matcher.pathname.split('/').some(i => `/${i}` === item.to)}
                hasAnchor={anchorEl != null}
                key={"hNavButton" + item.id}
                sx={{ height: "100%", fontSize: "1em" }}
            >
                {item.showIconInHorizontal && item.icon ? item.icon : null} {item.title}
            </StyledNavButton>
        )
    }

    return (
        showMenus["temp"] ?
            <>
                {
                    item.to !== undefined ?
                        <NavLink
                            to={item.to}
                            key={"hNav" + item.id}
                        >
                            {getNavButton()}
                        </NavLink>
                        :
                        getNavButton()
                }
                {
                    // TODO: Ovo bi bilo lepo da se sredi
                    // hasItems &&
                    // <Popper
                    //     anchorEl={anchorEl}
                    //     open={anchorEl != null}
                    //     key={"sub" + item.id}
                    //     transition
                    // >
                    //     {({ TransitionProps }) => (
                    //         <ClickAwayListener onClickAway={() => {
                    //             setAnchorEl(null)
                    //         }}>
                    //             <Fade {...TransitionProps} timeout={350}>
                    //                 <Paper sx={classes.horizontalMenuPaper}>
                    //                     {
                    //                         hasItems ?
                    //                             item?.items?.map((subitem: MenuItem) =>
                    //                                 <HorizontalNavLink item={subitem}
                    //                                     key={"hNavLink" + subitem.id}
                    //                                     onCloseParentCallback={() => handleSetAnchorEl(null)}
                    //                                     sx={classes.horizontalSubmenuLink} />
                    //                             )
                    //                             : undefined
                    //                     }
                    //                 </Paper>
                    //             </Fade>
                    //         </ClickAwayListener>
                    //     )}
                    // </Popper>
                }
            </>
            : null
    )
}


interface MenuHorizontalNavProps {
    menu: MenuItem[];
}
export function MenuHorizontalNav (props: PropsWithChildren<MenuHorizontalNavProps>) {
    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            {
                props.menu.map((menuItem: MenuItem) => {
                    let show = menuItem.showInNavBar !== undefined ? menuItem.showInNavBar : true;
                    if (show) {
                        if (menuItem.to === '/') {
                            return undefined;
                        }
                        return (
                            <HorizontalNavLink item={menuItem} key={"hNavLink" + menuItem.id} />
                        );
                    }
                    else return undefined;
                })
            }
            {props.children}
        </div>
    );
}