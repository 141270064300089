export const VALUTA = "RSD";

export const LANG_SR_LAT = "sr-LAT";
export const LANG_SR_CIR = "sr-CIR";

export const langHeaderValues = {
    [LANG_SR_LAT] : "lat",
    [LANG_SR_CIR] : "cyr"
}

export const appNames = {
    selectedLanguage: "__srbijagas__selected_language_",
    DEFAULT_LOCALE: LANG_SR_CIR,
}

export const NADLEZNOST_BEOGRAD = 1;
export const NADLEZNOST_NOVI_SAD = 2;

export const TIP_FIZICKO_LICE = "F";
export const TIP_PRAVNO_LICE = "P";

export const PARTNER_OZNAKA_DA = "D";
export const PARTNER_OZNAKA_NE = "N";

export const IOS_STATUS_DEL_PLACEN = 1;
export const IOS_STATUS_NE_PLACEN = 0;
export const IOS_STATUS_TUZEN = -1;

export const MAX_LEN_100_CHAR = 100;
export const MAX_LEN_1000_CHAR = 1000;
export const MAX_LEN_PORUKE_PODRSCI = 500;
export const MIN_MI_ZA_PRETRAGU = 5;

/**
 * za onKeyPress event.key
 */
export const KEY_ENTER = 'Enter';
export const KEY_ESC = 'Escape';

export const FORMAT_DATUM_VREME = "DD.MM.YYYY HH:mm:ss"; // moment formate
export const FORMAT_DATUM_VREME_BEZ_SS = "DD.MM.YYYY HH:mm"; // moment formate
export const FORMAT_DATE = "DD.MM.YYYY"; // moment formate

export const colors = {
    SGAS_PRIM_COLOR : "rgba(0,100,177,0.74)",
    SGAS_SEC_COLOR : 'rgba(0,61,120,0.84)',// "#003d78A6",
    placen:"#6DA775A6",
    delimicnoPlacen:"#6D8EA7A6",
    nijePlacen:"#A79B6DA6",
    utuzen:"#A76D6DA6",
    storniran:"#ADADADA6",
    logoText: "#1569bb",

    hTextColor: "rgba(0, 0, 0, 0.54)",
    
    BORDER_COLOR: "#DCDCDC",
    ROW_SHADE_COLOR: "#f5f5f5",
    ROW_HOVER_COLOR: "#fae8be",
    ROW_SELECTED_COLOR: "#edb985a3",

}