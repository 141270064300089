import { createQueryParams, fetchResource, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DigitResponse } from "../util/digitInterfaces";
import { Novost, PostNovostiBody } from "../model/novosti/novostiModel";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";

const address = config["razvoj"]  ? config["srbijagas.novosti.razvoj"] : config["srbijagas.novosti"];
const domain = "/novosti";

export function getSlikuNovosti(idNovosti: number, fileName: string){
    return `${address}${domain}/${idNovosti}/${fileName}`;
}

export interface DeleteNovostQuery {
    idNovosti: number;
    /**  logicko brisanje ako je false */
    zauvek?: boolean;
}


// TODO: TEST prodji jos jednom kroz ovo
export const novostiApi = createApi({
    reducerPath: "novosti",
    baseQuery: fetchBaseQuery({
        baseUrl: `${address}${domain}`,
        prepareHeaders: (headers)=>updateHeaders(headers)
    }),
    endpoints: (builder) =>({
        getNovost: builder.query<DigitResponse<Novost>, number>({
            query: (idNovosti: number)=> `${idNovosti}`
        }),
        deleteNovost: builder.mutation<DigitResponse<Novost>, DeleteNovostQuery>({
            query: (q: DeleteNovostQuery)=> `${q.idNovosti}?zauvek=${q.zauvek!== undefined? q.zauvek : false}`
        }),
        postNovost: builder.mutation<DigitResponse<Novost>, PostNovostiBody>({
            query(body){
                return {
                    url: `update`,
                    method: "POST",
                    body
                }
            },
            // TODO: add invalidated tags
        }),
        getSlikeNovosti: builder.query<DigitResponse<string[]>, number>({
            query: (idNovosti: number)=> `${idNovosti}/slike`
        }),
        getNovostiUkratko: builder.query<DigitResponse<PageableWithData<Novost>>, PaginationQuery|void>({
            query: (filter?: PaginationQuery)=> `ukratko${fixQuery(createQueryParams(filter))}`
        }),
    })
})

export const {
    useDeleteNovostMutation,
    useGetNovostQuery,
    useGetNovostiUkratkoQuery,
    useGetSlikeNovostiQuery,
    usePostNovostMutation
}= novostiApi

export function fetchNovostSlika(idNovosti: number,fileName: string){
    return fetchResource(getSlikuNovosti(idNovosti, fileName));
}
