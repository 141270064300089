
import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, fetchResource, updateHeaders } from "./apiCalls";
import config from "./config.json";

/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ObPlacanja } from "../model/iop/obPlacanjaModel";

const address = config["razvoj"]  ? config["srbijagas.iop.razvoj"] : config["srbijagas.iop"];
const domain = "/obezbedjenje";

export interface ObPlacanjaFilter extends Row, PaginationQuery{
    partneri?: number[];
    brojeviUgovora?: string[];
}

export function getObPlacanjaBaseQuery(){
    return `${address}${domain}`
}

export const iopApi = createApi({
    reducerPath: "iop",
    baseQuery: fetchBaseQuery({
        baseUrl: getObPlacanjaBaseQuery(),
        prepareHeaders:(headers)=>{
            return updateHeaders(headers);
        }
    }),
    endpoints: (builder)=>({
        getObezbedjenjaPlacanja: builder.query<DigitResponse<PageableWithData<ObPlacanja>>, ObPlacanjaFilter|void>({
            query: (filter?: ObPlacanjaFilter)=>`${fixQuery(createQueryParams(filter))}`
        }),
    })
})

export const {useGetObezbedjenjaPlacanjaQuery} = iopApi

/** Vraca page, namenjeno pretezno za admine za pretragu */
export function fetchObPlacanjaPdf(iopId: number): Promise<string | null | DigitResponse<any>>{    
    return fetchResource(`${address}${domain}/pdf/${iopId}`)
}