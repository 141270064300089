import FileSaver from "file-saver";
import { DigitResponse, KeycloakRespose, Row } from "../util/digitInterfaces";
import { langHeaderValues } from "../util/konstante";
import { getLanguage, getValue, isEmpty } from "../util/util";

export function fetchResource(url: string|undefined): Promise<string | null | DigitResponse<any>>{
    if(url === undefined){
        return new Promise<string|null>((response)=>null);
    }
    return fetch(url, signRequest({
        headers: {
            method: "GET"
        }
    })).then(response=>{
        if(response.ok){
            return response.blob().then(blob=>URL.createObjectURL(blob))
        }
        else{
            return response.text().then((response)=>({message: response, content: null, hasMsg: true}))
        }
    }, err => {
        return null;
    })
}
/**
 * Preuzima pdf direktno ili vraca poruku o greski kroz DigitResponse, null ako je sve ok 
 */
export function downloadPdfResource(url: string|undefined, fileName: string) :  Promise<null|DigitResponse<any>>{
    if(url === undefined){
        return new Promise<null>((response)=>null);
    }
    return fetch(url, signRequest({
        headers: {
            method: "GET"
        }
    })).then(response=>{
        if(response.ok){
            return response.blob().then(theBlob=> {
                const file = new Blob([theBlob], {type: 'application/pdf'});
                FileSaver.saveAs(file, `${fileName}.pdf`);
                return null;
            })
        }
        else{
            return response.text().then((response)=>({message: response, content: null, hasMsg: true})) 
        }
    }, err => {
        return null;
    })
}

export function downloadPdfResourceNoHeader(url: string|undefined, fileName: string) :  Promise<null|DigitResponse<any>>{
    if(url === undefined){
        return new Promise<null>((response)=>null);
    }
    return fetch(url, {
        headers: {
            method: "GET"
        }
    }).then(response=>{
        if(response.ok){
            return response.blob().then(theBlob=> {
                const file = new Blob([theBlob], {type: 'application/pdf'});
                FileSaver.saveAs(file, `${fileName}.pdf`);
                return null;
            })
        }
        else{
            return response.text().then((response)=>({message: response, content: null, hasMsg: true}))
        }
    }, err => {
        return null;
    })
}

export function postFormData(dataUri: string, data: FormData): Promise<Response> {
    return fetch(dataUri, {
        body: data,
        method: "POST"
    })
}

export function createQueryParams(paramValues: Row|undefined): string|undefined {
    if(paramValues === undefined){
        return undefined
    }
    let params = new URLSearchParams(paramValues);
    let keysForDel: string[] = [];
    params.forEach((value, key) => {
        if (isEmpty(value)) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });

    return params.toString();
}

function getAuthHeader(){
    if(sessionStorage.getItem("test")==null){
        return "";
    }
    let info : KeycloakRespose = JSON.parse(JSON.parse(sessionStorage.getItem("test")||"{}"))
    return `${info["tokenType"]} ${info["accessToken"]}`;
}

function getRefreshTokenHeader(){
    if(sessionStorage.getItem("test")==null){
        return "";
    }
    let info : KeycloakRespose = JSON.parse(JSON.parse(sessionStorage.getItem("test")||"{}"))
    return info["refreshToken"];
}

export function setLangHeader(headers: Headers){
    headers.set("Accept-Language", getValue(getLanguage(), langHeaderValues));
    return headers;
}

export function updateHeaders(headers: Headers){
    headers.set("Authorization", getAuthHeader())
    headers.set("RefreshToken", getRefreshTokenHeader())
    headers.set( "Access-Control-Allow-Origin", "*")
    setLangHeader(headers);
    return headers;
}

export function getHeaders(){
    return {
        "Authorization": getAuthHeader(),
        "RefreshToken": getRefreshTokenHeader(),
        "Access-Control-Allow-Origin": "*"
    }
}

/** obavezno proslediti method  */
export function signRequest(requestParams: RequestInit):RequestInit{
    return ({
        ...requestParams,
        headers: {
            ...requestParams.headers,
            "Authorization": getAuthHeader(),
            "RefreshToken": getRefreshTokenHeader(),
            "Access-Control-Allow-Origin": "*"
        }
    })
}
