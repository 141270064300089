import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FinansijskaKartica, FinKarticaSume } from "../model/finKartica/finKarticaModel";

const address = config["razvoj"]  ? config["srbijagas.finKartica.razvoj"] : config["srbijagas.finKartica"];
const domain = "/finKartica";

export function getFinKarticaBaseQuery(){
    return `${address}${domain}`;
}

export interface FinKarticaFilter extends Row, PaginationQuery {
    partneri?: number[];
    sifreMestaIsporuke?: string[];
    datumOd?: string;
    datumDo?: string;
    samoUplate?: boolean;
}


export const finKarticaApi = createApi({
    reducerPath: "finKartica",
    baseQuery: fetchBaseQuery({
        baseUrl: getFinKarticaBaseQuery(),
        prepareHeaders: (headers) => updateHeaders(headers)
    }),
    endpoints: (builder)=>({
        getFinKarticu: builder.query<DigitResponse<PageableWithData<FinansijskaKartica>>, FinKarticaFilter|void>({
            query: (filter?: FinKarticaFilter)=> `${fixQuery(createQueryParams(filter))}`
        }),
        getSumeFinKartice: builder.query<DigitResponse<FinKarticaSume>, FinKarticaFilter|void>({
            query: (filter?: FinKarticaFilter)=> `sume${fixQuery(createQueryParams(filter))}`
        })
    })
})

export const selectFinKarticaResult = finKarticaApi.endpoints.getFinKarticu.select();

export const {useGetFinKarticuQuery, useGetSumeFinKarticeQuery} = finKarticaApi