import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Korisnik from "../../../model/korisnikPortala/korisnikPortalaModel";
import { selectKorisnik, useGetKorisnikQuery } from "../../../network/kportalaApiCalls";

export default function useKorisnik(){

    const korisnikHook = useGetKorisnikQuery();

    const [korisnik, setKorisnik] = useState(korisnikHook.data?.content)

    useEffect(()=>{
        setKorisnik(korisnikHook.data?.content)
    }, [korisnikHook.data?.content])

    return korisnik

}