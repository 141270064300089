import { HomeOutlined } from "@mui/icons-material";
import i18next from "i18next";
import { lazy, LazyExoticComponent } from "react";
import { SGAS_ROLE } from "../../../model/role";
import administracijaMenu from "./submenus/administracijaMenu";
import korisnickaPodrskaMenu from "./submenus/korisnickaPodrska";
import mestaIsporukeMenu from "./submenus/mestaIsporuke";
import pregledMenu from "./submenus/pregled";

const RegistracijaNaPortal = lazy(() => import("../../../pages/RegistracijaNaPortal"));
const ZaboravljenaLozinka = lazy(() => import("../../../pages/ZaboravljenaLozinka"));
const Pregled = lazy(() => import('../../../pages/Pregled'))
const Podrska = lazy(() => import('../../../pages/Podrska'))
const Nalog = lazy(() => import('../../../pages/Nalog'))
const MestaIsporuke = lazy(() => import('../../../pages/MestaIsporuke'))
const Novosti = lazy(() => import('../../../pages/Novosti'))
const Administracija = lazy(() => import('../../../pages/Administracija'))
const Pocetna = lazy(() => import('../../../pages/Pocetna'))

/** Ideja je da se vise ne koristi component za prikaz trenutne stranice niti url path, vec da se rucno odredjuje
 * koja komponenta treba da se prikaze. Ako se dodeljuje nekom kljucu mora biti isti id i naziv kljuca {[menuItemId: string]: MenuItem}
 */
export interface MenuItem {
    icon?: JSX.Element,
    title: string,
    to: string,
    id: string,
    items?: MenuItem[],
    // component?: JSX.Element,
    showInNavBar?: boolean, // ako se ne prikazuje ali postoji kao ruta
    route?: any, // za potrebe stranica na koje se dolazi kroz druge uz prosledjivanje parametara
    routePath?: string, // kad se navede route popuniti i ovo dok se ne smisli bolji nacin
    isForHighlit?: boolean,// ako se prikazuje kao card na pocetnoj stranici
    showIconInHorizontal?: boolean;
    visibleToAdminOnly?: boolean;

    rolesNeeded?: string[];
    hiddenForAdmin?: boolean;
    hiddenForPravno?: boolean;
    hiddenForFizicko?: boolean;

    component?: LazyExoticComponent<() => JSX.Element>;
    /** u slucaju kada se prikazuje header na template strani, moze se razdvojiti na odvojen prop */
    titleVisible?: boolean;

    state?: { [key: string]: any };
}
/** key i id iz menuItem moraju biti isti kako bi router radio. */
type SGasMenuType = {
    pocetna: MenuItem;
    pregled: MenuItem;
    korisnickaPodrska: MenuItem;
    nalog: MenuItem;
    mestaIsporuke: MenuItem;
    novosti: MenuItem;
    administracija: MenuItem;
    registracija: MenuItem;
    zaboravljenaLozinka: MenuItem;
}

/** GLAVNI MENI
 * unutar items se nelaze submeniji, koji se dalje znaju sami nestovati
 */
const SGasMenu = (): SGasMenuType => {
    return {
        pocetna: {
            icon: <HomeOutlined />,
            title: i18next.t("page-pocetna.menu-item"),
            to: "/pocetna",
            id: "pocetna",
            component: Pocetna,
            titleVisible: false,
            showInNavBar: false
        },
        pregled: {
            title: i18next.t("page-pregled.navTitle"),
            id: "pregled",
            to: "/pregled",
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            items: Object.values(pregledMenu()),
            component: Pregled
        },
        korisnickaPodrska: {
            title: i18next.t("page-korisnicka-podrska.navTitle"),
            id: "korisnickaPodrska",
            to: "/podrska",
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: Podrska,
            items: Object.values(korisnickaPodrskaMenu())
        },
        nalog: {
            title: i18next.t("korisnicki-podaci.navTitle"),
            id: "nalog",
            to: "/nalog",
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: Nalog,
            showInNavBar: false
        },
        mestaIsporuke: {
            title: i18next.t("mesta-isporuke.navTitle"),
            id: "mestaIsporuke",
            to: "/mestaIsporuke",
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: MestaIsporuke,
            items: Object.values(mestaIsporukeMenu())
        },
        novosti: {
            title: i18next.t("novosti.navTitle"),
            id: "novosti",
            to: "/novosti",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            component: Novosti
        },
        administracija: {
            title: i18next.t("administracija.navTitle"),
            id: "administracija",
            to: "/administracija",
            rolesNeeded: [SGAS_ROLE.ROLE_ADMIN],
            component: Administracija,
            items: Object.values(administracijaMenu())
        },
        registracija: {
            title: i18next.t("page-registracija.navTitle"),
            id: "registracija",
            to: "/registracija",
            showInNavBar: false,
            component: RegistracijaNaPortal
        },
        zaboravljenaLozinka: {
            title: i18next.t("page-zaboravljena-lozinka"),
            id: "zaboravljenaLozinka",
            to: "/zaboravljenaLozinka",
            showInNavBar: false,
            component: ZaboravljenaLozinka
        }
    };
}

function findMenuItemRec (exactPath: string, menuItems: MenuItem[]): MenuItem | undefined {
    for (let menuItem of menuItems) {
        if (menuItem.to !== undefined && menuItem.to === exactPath) {
            return menuItem;
        }
        if (menuItem.routePath !== undefined && menuItem.routePath === exactPath) {
            return menuItem;
        }
        if (menuItem.items !== undefined) {
            let result = findMenuItemRec(exactPath, menuItem.items);
            if (result) {
                return result;
            }
        }
    };
    return undefined;
}

export function findMenuItem (exactPath: string) {
    if (SGasMenu) {
        let result = findMenuItemRec(exactPath, Object.values(SGasMenu()));
        return result;
    }
    return undefined;
}

export default SGasMenu;