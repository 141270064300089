import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import TextInput from "./TextInput";

const formElements = {
    text: TextInput,
    datepicker: DatePicker,
    checkbox: Checkbox,
}

export default formElements;