import { KeyboardArrowDown } from "@mui/icons-material";
import { styled, SvgIconProps } from "@mui/material";

interface IconCollapseProps extends SvgIconProps {
    componentIsOpened?: boolean;
}

const IconCollapse = styled(KeyboardArrowDown, {
    shouldForwardProp: (prop)=> prop!=="componentIsOpened"
})<IconCollapseProps>(({componentIsOpened, theme})=>({
    margin: "5px",
    transition: "transform 0.2s ease-in-out, background-color 0.3s ease-in-out",
    borderRadius: "100%",
    alignSelf: "center",
    "&:hover":{
        backgroundColor: theme.palette.grey.A200,
        cursor: "pointer"
    },
    ...(componentIsOpened && {
        transform: "rotate(180deg)"
    })
}));

export default IconCollapse;