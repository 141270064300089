import { DesktopDatePickerProps, DesktopDatePicker as MuiDatePicker } from "@mui/lab";
import { FormControlLabelProps, TextField } from "@mui/material";
import { Moment } from "moment";
import { useEffect } from "react";
import { ColorOptions } from "../../../util/digitInterfaces";
import { FORMAT_DATE } from "../../../util/konstante";
import LabeledValue from "../../LabeledValue";

export interface DatePickerPropTypes extends DesktopDatePickerProps{
    readOnly?: boolean;
    label?: string|JSX.Element;
    labelProps?: FormControlLabelProps;
    required?: boolean;
    helperText?: string;
    color?: ColorOptions;
}

export default function DatePicker(props: DatePickerPropTypes){

    const {readOnly, label, labelProps, required, onChange, helperText, color, ...otherProps} = props;


    useEffect(()=>{}, [props]);

    const handleDateChange = (date: any, value?: any) => {
        props.onChange({target: {
            value: date,
            role: "datepicker"
        }});
    };

    const field = (
        <MuiDatePicker
            inputFormat={props.inputFormat || FORMAT_DATE}
            {...otherProps}
            renderInput={(params) => (
                <TextField variant="outlined" label="" helperText={helperText} color={color} {...params}/>
            )}
            onChange={(date, value)=>handleDateChange(date, value)}
            label=""
        />
    );

    if(label){
        return (
            <LabeledValue {...labelProps} label={label} required={required}>
                {field}
            </LabeledValue>
        );
    }

    return field;
}