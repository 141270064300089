import { lighten, SxProps, Theme } from "@mui/material";

type CommonStylesType = {
    plavaPozadina: SxProps<Theme>;
    sivaTankaIvicaLevoJaca: SxProps<Theme>;
    margineHedera: SxProps<Theme>;
    flexMaxW25WrapOverflow: SxProps<Theme>;
    coloredHeader: SxProps<Theme>;
    inlineFlexRow: SxProps<Theme>;
    inlineFlexColumn: SxProps<Theme>;
    mainSecText: SxProps<Theme>;
    errorMessage: SxProps<Theme>;
}

const commonStyles : CommonStylesType = {

    plavaPozadina: {
        backgroundColor: (theme)=>lighten(theme.palette.primary.light, 0.5),
        color: (theme)=>theme.palette.text.secondary,
        "& *":{
            color: (theme)=>theme.palette.text.secondary
        }
    },

    sivaTankaIvicaLevoJaca: {
        border: (theme)=>`0.25pt solid ${theme.palette.grey[400]}`,
        borderLeft: (theme)=>`5pt solid ${theme.palette.grey[400]} !important`,
    },

    margineHedera:{
        margin: (theme)=> `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`
    },

    flexMaxW25WrapOverflow:{
        maxWidth: "25rem", 
        display: "inline-flex",
        flexFlow: "wrap",
        alignSelf: "stretch"
    },
    coloredHeader : {
        padding: (theme)=>theme.spacing(1),
        marginBottom: (theme)=>theme.spacing(2),
        borderLeft: (theme)=> `10pt solid ${theme.palette.primary.dark}`,
        color: (theme)=>theme.palette.secondary.main
    },
    inlineFlexColumn:{
        display:"inline-flex", 
        flexDirection: "column",
    },
    inlineFlexRow:{
        display:"inline-flex", 
        flexDirection: "row",
        flexWrap: "wrap",
        "&>*": {
            flex: 1
        },
        "&>*:not(:last-child)":{
            mr: (theme) => theme.spacing(3),
        }
    },
    mainSecText:{
        color: (theme)=>theme.palette.secondary.main
    },
    errorMessage:{
        color: (theme)=>theme.palette.error.main,
    }
}

export default commonStyles;