import { AppBar, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { downloadUslovProdajePdf } from "../../network/registracijaApiCalls";


const useStyles = ():{[key: string]:SxProps<Theme>} => ({
    title: {
      flexGrow: 1,
    },
    appBar: {
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: grey[600],
      color: grey[300],
      "& > *":{
          marginBottom: "0.5em",
          "&:last-child":{
              marginBottom: "0em",
          }
      },
      minHeight: "200px",
      display:"flex",
      flexDirection:"column",
      padding:"20px",
      alignItems:"center",
      "& p":{
        marginTop: "0px",
        marginBottom:"10px"
      },
      "& a":{
        marginTop: "0px",
        marginBottom:"10px"
      },
      "& *":{
        fontWeight: "bold"
      }
    },
    lightBlueText:{
      color: "rgb(167, 214, 250)"
    }
  });

function Footer(props: WithTranslation) {
    const classes = useStyles();
  const {t} = props;
    const [errMsgPreuzimanja, setErrMsgPreuzimanja] = useState<string|null>(null);

    const preuzmiPdf = (tipPotrosaca : string, filename : string) =>{

      downloadUslovProdajePdf(tipPotrosaca, filename).then(v=>{
          if(v!=null){
              setErrMsgPreuzimanja(v.message);
          }
      })
  }

    return (
        <AppBar position="static" sx={classes.appBar} style={{backgroundImage:"url(/footer.jpg)"}}>
          <div style={{display:"flex", flexDirection:"row", width: "100%"}}>
            <div style={{display:"inline-flex", flexDirection:"column"}}>
                <a color="inherit" href="#" onClick={()=>preuzmiPdf('P', 'opsti_uslovi_prodaje_slobodno_trziste')}>
                    {t('common.opsti-uslovi-prodaje-slobodno-trziste')}
                </a>
                <a color="inherit" href="#" onClick={()=>preuzmiPdf('F', 'opsti_uslovi_prodaje_domacinstva')}>
                    {t('common.opsti-uslovi-prodaje-domacinstva')}
                </a>
            </div>
          </div>         
          <Typography variant="body1" sx={classes.title} >
            {t('common.digit-copyright')}
          </Typography>
        </AppBar>
    )
}
export default withTranslation()(Footer);