import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { CSSProperties, useEffect } from "react";
import ioFieldStyles from "../../ioFieldStyles";
import LabeledValue, { LabeledValueOptional } from "../../LabeledValue";
import { FormElementPropTypes } from "../useForm";

export interface TextInputPropTypes extends OutlinedTextFieldProps, FormElementPropTypes {
    readOnly?: boolean;
    TextFieldStyle?: CSSProperties;
    label?: string|JSX.Element;
    labelProps?: LabeledValueOptional;
}

const DEFAULT_MULTILINE_MIN_ROWS = 6;

export default function TextInput (props: TextInputPropTypes) {

    const { readOnly, label, labelProps, TextFieldStyle, ...otherProps } = props;

    useEffect(()=>{}, [props]);

    const classes = ioFieldStyles();

    const field = (
        <TextField
            {...otherProps}
            required={false}
            size="small"
            inputProps={{
                readOnly: props.readOnly !== undefined ? props.readOnly : false,
                style: { textAlign: (props.type === "numeric" || props.type === "numeric-no-dec") ? "right" : "left" },
            }}
            minRows={props.multiline ? (props.minRows || DEFAULT_MULTILINE_MIN_ROWS) : undefined}
            style={{ width: "100%", ...(props.TextFieldStyle ? props.TextFieldStyle : {}) }}
            sx={classes.filterField}
            label=""
        />
    )

    if(label){
        return <LabeledValue {...labelProps} label={label} required={props.required}>
            {field}
        </LabeledValue>
    }
    return field;
}