import { createTheme, Theme, ThemeOptions } from "@mui/material"
import { colors } from "./util/konstante";

const hCommonStyle = {
    color: colors.hTextColor,
    fontWeight: "bold"
}

/**
 * Dodatno su obradjene neke klase u .css
 */
const srbijagasThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: colors.SGAS_PRIM_COLOR,
            contrastText: '#ffffff',
        },
        secondary: {
            main: colors.SGAS_SEC_COLOR,
            contrastText: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontSize: '3.5rem',
            ...hCommonStyle
        },
        h2: {
            fontSize: '3rem',
            ...hCommonStyle
        },
        h3: {
            fontSize: '2.4rem',
            ...hCommonStyle
        },
        h4: {
            fontSize: '1.9rem',
            ...hCommonStyle
        },
        h5:{
            ...hCommonStyle
        },
        h6:{
            ...hCommonStyle
        },
        button: {
            fontSize: '1rem',
        },
        subtitle1:{
            fontWeight: 700
        }
    },
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiList: {
            styleOverrides: {
                dense: true,
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                dense: true,
            }
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiButton: {
            defaultProps: {
                size: 'small',
                sx:{"& button:focus":{backgroundColor:"primary.main"}}
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiSwitch: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiPaper:{
            defaultProps:{
                elevation: 10,
                square: true,
            }
        },
        MuiContainer:{
            defaultProps:{
                sx:{
                    maxWidth: "100%"
                }
            }
        },
        MuiChip:{
            defaultProps:{
                sx:{
                    borderRadius: "0px"
                }
            }
        }
    }
}

const srbijagasTheme = createTheme(srbijagasThemeOptions)

export default srbijagasTheme;