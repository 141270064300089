import { InputBase, MenuItem as MItem, OutlinedInput, Select } from '@mui/material';
import i18next from 'i18next';
import React, { Suspense } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import DCircularProgress from './components/DCircularProgress';
import useKorisnik from './components/mainComponents/auth/useKorisnik';
import Footer from './components/mainComponents/Footer';
import Header from './components/mainComponents/Header';
import SGasMenu, { MenuItem } from './components/mainComponents/menu/Meni';
import pregledMenu, { getMenuSubItemPregled } from './components/mainComponents/menu/submenus/pregled';
import StranicaNijePronadjena from './components/mainComponents/StranicaNijePronadjena';
import TemplateContent from './components/mainComponents/templatePage/TemplatePage';
import { selectObrPeriodiNazivi } from './model/faktura/fakturaSelectors';
import { useGetObrPeriodeQuery } from './network/fakturaApiCalls';
import srCir from "./util/bundle/messages-cir.json";
import srLat from "./util/bundle/messages-lat.json";
import useShowMenuItem from './util/hooks/useShowMenuItem';
import { LANG_SR_CIR, LANG_SR_LAT } from './util/konstante';
import { getLanguage, getValue, setLanguage } from './util/util';

i18next
.use(initReactI18next)
.init({
  lng: getLanguage(),
  resources: {
    [LANG_SR_LAT]: srLat,
    [LANG_SR_CIR]: srCir, 
  },
  debug: false,
});

function SelectLanguage(){
  
  const handleChangeLang = (value: string)=>{
    setLanguage(value);
    i18next.changeLanguage(value);
  }
  const {t} = useTranslation();
  return (
    <Select
      value={getLanguage()}
      onChange={(event)=>handleChangeLang(event.target.value)}
      input={<InputBase style={{width: "100%"}}/>}
    >
      <MItem value={LANG_SR_CIR}> {t("sr-cirilica")} </MItem>
      <MItem value={LANG_SR_LAT}> {t("sr-latinica")} </MItem>
    </Select>
  );
}
function getMenuItemForValue (menuItems: MenuItem[], keyAttr: string, keyValue: string, returnAttr: string): any {

  for (let mi of menuItems) {
    if (getValue(keyAttr, mi) === keyValue) {
      return getValue(returnAttr, mi);
    }
    if (mi.items !== undefined && mi.items.length !== 0) {
      return getMenuItemForValue(mi.items, keyAttr, keyValue, returnAttr);
    }
  }
  return undefined;
}

function hasPathAsSubitem (menuItems: MenuItem[], path: string): boolean {
  for (let mi of menuItems) {
    if (path.split('/').some(i => `/${i}` === mi.to)) {
      return true;
    }
    if (mi.items !== undefined && mi.items.length !== 0) {
      return hasPathAsSubitem(mi.items, path);
    }
  }
  return false;
}

const App: React.FC = () => {

  const korisnik = useKorisnik();
  
  const match = useLocation();
  
  const oPeriodiHook = useGetObrPeriodeQuery();
  const oPeriodiNazivi =  useSelector(selectObrPeriodiNazivi);
  
  const getMenu = () => {
    let menu = SGasMenu();
    let pregled = pregledMenu();
    if (oPeriodiNazivi?.length !== 0) {
      menu.pregled.items = Object.values(pregled).map(p=>{
        if(p.id === pregled.pregledRacuna.id){
          return {
            ...p,
            items: oPeriodiNazivi?.map(op => getMenuSubItemPregled(op)) ||[]
          }
        }
        return p
      })
    }
    return menu;
  }
  const showMenus = useShowMenuItem({menuItems: getMenu(), korisnik: korisnik})

  const findTitle = (matchUrl: string): string | undefined => {
    if (getMenuItemForValue(Object.values(getMenu()), "to", matchUrl, "titleVisible") !== false) {
      return getMenuItemForValue(Object.values(getMenu()), "to", matchUrl, "title");
    }
    return undefined;
  }

  const findSubmenu = (matchUrl: string): MenuItem[] => {
    let lastExisting: MenuItem[] = [];

    for (let mi of Object.values(getMenu())) {
      if (matchUrl.startsWith(mi.to)) {
        if (mi.items) {
          lastExisting = mi.items;
        }
      }
      if (mi.items) {
        if (hasPathAsSubitem(mi.items, matchUrl))
          lastExisting = mi.items;
      }
    }
    return lastExisting;
  }

  return (
    <>
      <Header>
        <SelectLanguage/>
      </Header>
      <TemplateContent
        headerProps={findTitle(match.pathname) === undefined ? undefined : {
          title: findTitle(match.pathname)
        }}
        leftNavProps={(findSubmenu(match.pathname).length !== 0 ? {
          submenu: findSubmenu(match.pathname)
        } : undefined)}
      >
        <Suspense fallback={<DCircularProgress hidden={false} />}>
          <Switch>
            <Redirect exact from="/" to={getMenu().pocetna.to} />
            {
              Object.values(getMenu()).map((item: MenuItem) => {
                return (
                  <Route path={item.to} key={item.id} component={showMenus[item.id] ? item.component : undefined}>
                    {
                      !showMenus[item.id] && <Redirect to={getMenu().pocetna.to} />
                    }
                  </Route>
                )
              })
            }
            <Route path="*">
              <StranicaNijePronadjena />
            </Route>
          </Switch>
        </Suspense>
      </TemplateContent>
      <Footer />
    </>
  )
}

export default App
