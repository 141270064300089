import { AccountCircleOutlined, Logout } from "@mui/icons-material";
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { postLogout } from "../../../model/login/loginSlice";
import { fetchPartnerSlika } from "../../../network/ppartnerApiCalls";
import commonStyles from "../../../util/commonStyles";
import { DigitResponse } from "../../../util/digitInterfaces";
import SGasMenu from "../menu/Meni";
import useKorisnik from "./useKorisnik";

export default function SGLogout(props: {onSelectOption: Function}){

    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [slika, setSlika] = useState<string|null|DigitResponse<any>>(null);

    const korisnik = useKorisnik();

    useEffect(()=>{
        fetchPartnerSlika().then(s=>setSlika(s))
    },[]);

    // useEffect(()=>{
    //     // dispatch(ucitajSlikuPartnera())
    //     return ()=>{
    //         if(urlSlike !== undefined){
    //             URL.revokeObjectURL(urlSlike)
    //         }
    //     }
    // }, [])
    
    const nalogAction = ()=>{
        history.push(SGasMenu().nalog.to)
        props.onSelectOption();
    }

    const odjavaAction = ()=>{
        dispatch(postLogout())
        props.onSelectOption();
        history.push("");
    }

    return (
        <Box display="flex" flexDirection="column" padding={(theme)=>theme.spacing(2)}>
            {
                typeof slika === "string" &&
                <img src={slika} alt="MOJ SRBIJAGAS" style={{maxWidth: "200px", height: "auto"}}/>
            }
            <Typography variant="h6" sx={{display: "inline", color: (theme)=> theme.palette.secondary.dark}}>
                {korisnik?.naziv}
            </Typography>
            { korisnik?.jeAdministrator &&
                <Typography variant="body1" sx={{display: "inline", color: (theme)=> theme.palette.secondary.dark}}>
                    {t("common.admin")}
                </Typography>
            }
            <List>
                <ListItemButton onClick={nalogAction}>
                    <ListItemIcon><AccountCircleOutlined sx={commonStyles?.mainSecText}/></ListItemIcon>
                    <ListItemText primary={SGasMenu().nalog.title} />
                </ListItemButton>
                <ListItemButton onClick={odjavaAction}>
                    <ListItemIcon><Logout sx={commonStyles?.mainSecText}/></ListItemIcon>
                    <ListItemText primary={t("common.logout")} />
                </ListItemButton>
            </List>
        </Box>
    );
}