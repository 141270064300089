import config from "./config.json";

const address = config["razvoj"]  ? config["srbijagas.auth.razvoj"] : config["srbijagas.auth"];
const domainAuth = "/auth";

export function getAuthBaseQuery(){
    return `${address}${domainAuth}`;
}

export function postLogin(data: FormData){
    let url = `${address}${domainAuth}/login`;
    
    return fetch(url,{
        method:"POST",
        headers: {
            'Accept': 'application/json',
        },
        body: data
    })
}

