
import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, fetchResource, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UgovorModel } from "../model/ugovor/ugovorModel";

const address = config["razvoj"]  ? config["srbijagas.ugovor.razvoj"] : config["srbijagas.ugovor"];
const domain = "/ugovor";

export interface UgovoriFilter extends Row, PaginationQuery{
    partneri?: number[];
    sifreMesta?: string[];
    brojPredmeta?: string[];
}

export function getUgovorBaseQuery(){
    return `${address}${domain}`
}


export function getPdfUgovora(idUgovora: number){
    return `${address}${domain}/pdf/${idUgovora}`
}

export function getPdfIopUgovora(idUgovora: number){
    return `${address}${domain}/pdfIop/${idUgovora}`
}

export const ugovorApi = createApi({
    reducerPath: "ugovori",
    baseQuery: fetchBaseQuery({
        baseUrl: getUgovorBaseQuery(),
        prepareHeaders: (headers) => updateHeaders(headers)
    }),
    endpoints: (builder)=>({
        getUgovore: builder.query<DigitResponse<PageableWithData<UgovorModel>>,UgovoriFilter|void>({
            query: (filter?: UgovoriFilter)=>`${fixQuery(createQueryParams(filter))}`
        })
    })
})

export const {useGetUgovoreQuery} = ugovorApi

export function fetchPdfUgovora(idUgovora: number): Promise<string | null | DigitResponse<any>>{
    return fetchResource(getPdfUgovora(idUgovora))
}

export function fetchPdfIopUgovora(idUgovora: number): Promise<string | null | DigitResponse<any>>{
    return fetchResource(getPdfIopUgovora(idUgovora))
}