import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { doLogin, selectLoginStatus } from "../../../model/login/loginSlice";
import { useGetPartnerInfoQuery } from "../../../network/ppartnerApiCalls";
import SGasMenu from "../menu/Meni";

interface SGLoginProps {
	style?: CSSProperties;
	closePopup: Function;
}

export default function SGLogin (props: SGLoginProps) {

	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const partnerFetch = useGetPartnerInfoQuery();
	const loginStatus = useSelector(selectLoginStatus)

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		dispatch(doLogin(data, ()=>{
			partnerFetch.refetch();
		}));
	};

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	  };
	
	  const handleMouseDownPassword = () => {
		setShowPassword(!showPassword);
	  };

	useEffect(()=>{
		if(loginStatus.uspesno){
			props.closePopup();
			history.push(SGasMenu().pregled.to);
		}
	}, [loginStatus.uspesno])

	return (
		<Container component={Paper} maxWidth="xs" style={{ paddingTop: 10, paddingBottom: 10, ...(props.style || {}) }}>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlined />
				</Avatar>
				<Typography component="h1" variant="h5">
					{t("common.prijava-header")}
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="username"
						label={t("page-registracija.username")}
						name="username"
						autoComplete="username"
						autoFocus
						type="text"
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label={t("page-registracija.password")}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
							  <InputAdornment position="end">
								<IconButton
								  aria-label="toggle password visibility"
								  onClick={handleClickShowPassword}
								  onMouseDown={handleMouseDownPassword}
								  edge="end"
								>
								  {showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							  </InputAdornment>
							),
						  }}
						id="password"
						autoComplete="current-password"
					/>
					{
						!loginStatus.uspesno &&
						<Box hidden={loginStatus.poruka === undefined} sx={(theme)=>({ color: theme.palette.error.dark })}>
							{loginStatus.poruka}
						</Box>
					}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						startIcon={loginStatus.uspesno ? <CircularProgress size={"1rem"} sx={{ color: (theme) => theme.palette.common.white }} /> : null}
					>
						{t("common.login")}
					</Button>
					<Grid container>
						<Grid item xs>
							<Link variant="body2" onClick={()=>{
								history.push(SGasMenu().zaboravljenaLozinka.to)
								props.closePopup();
							}}>
								{t("page-registracija.zaboravljena-lozinka")}
							</Link>
						</Grid>
						<Grid item>
							<Link variant="body2" onClick={()=>{
								history.push(SGasMenu().registracija.to)
								props.closePopup();
							}}>
								{t("page-registracija.nemate-nalog")}
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* <Copyright sx={{ mt: 4, mb: 2 }} /> */}
		</Container>
	)

}