import i18next from "i18next";
import { lazy } from "react";
import { SGAS_ROLE } from "../../../../model/role";
import { MenuItem } from "../Meni";

const PonovnoUkljucenje = lazy(()=> import("../../../../pages/korisnickaPodrska/PonovnoUkljucenje"));
const PracenjeStatusaZahteva = lazy(()=> import("../../../../pages/korisnickaPodrska/PracenjeStatusaZahteva"));
const PromenaAdreseRacuna = lazy(()=> import("../../../../pages/korisnickaPodrska/PromenaAdreseRacuna"));
const PromenaLicnihInfo = lazy(()=> import("../../../../pages/korisnickaPodrska/PromenaLicnihInfo"));
const SkidanjeSaMreze = lazy(()=> import("../../../../pages/korisnickaPodrska/SkidanjeSaMreze"));
const ZahtevZaReklamaciju = lazy(()=> import("../../../../pages/korisnickaPodrska/ZahtevZaReklamaciju"));

type KorisnickaPodrskaMenuType = {
    ponovnoUkljucenje: MenuItem;
    skidanjeSaMreze: MenuItem;
    promenaAdreseRacuna: MenuItem;
    promenaLicnihInfo: MenuItem;
    pracenjeStatusaZahteva: MenuItem;
    zahtevZaReklamaciju: MenuItem;
}

export default function korisnickaPodrskaMenu(): KorisnickaPodrskaMenuType{
    return {
        ponovnoUkljucenje: {
            to: "/podrska/ponovnoUkljucenje",
            id: "ponovnoUkljucenje",
            title: i18next.t("page-korisnicka-podrska.ponovnoUkljucenje.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForAdmin: true,
            component: PonovnoUkljucenje
        },
        skidanjeSaMreze: {
            to: "/podrska/skidanjeSaMreze",
            id: "skidanjeSaMreze",
            title: i18next.t("page-korisnicka-podrska.skidanjeSaMreze.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForAdmin: true,
            component: SkidanjeSaMreze
        },
        promenaAdreseRacuna: {
            to: "/podrska/promenaAdreseRacuna",
            id: "promenaAdreseRacuna",
            title: i18next.t("page-korisnicka-podrska.promenaAdreseRacuna.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForAdmin: true,
            component: PromenaAdreseRacuna
        },
        promenaLicnihInfo: {// Sakriveno od lica koja su pravna
            to: "/podrska/promenaLicnihInfo",
            id: "promenaLicnihInfo", 
            title: i18next.t("page-korisnicka-podrska.promenaLicnihInfo.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForAdmin: true,
            hiddenForPravno: true,
            component: PromenaLicnihInfo
        },        
        zahtevZaReklamaciju: {
            to: "/podrska/zahtevZaReklamaciju",
            id: "zahtevZaReklamaciju",
            title: i18next.t("page-korisnicka-podrska.rekalamacije.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForAdmin: true,
            hiddenForFizicko: true,
            component: ZahtevZaReklamaciju
        },
        pracenjeStatusaZahteva: {
            to: "/podrska/pracenjeStatusaZahteva",
            id: "pracenjeStatusaZahteva",
            title: i18next.t("page-korisnicka-podrska.pracenjeStatusaZahteva.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PracenjeStatusaZahteva
        },
    }
}