import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.css'

import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import store, { persistor } from './store'
import { BrowserRouter } from 'react-router-dom'
import srbijagasTheme from './muiStyle'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme = {srbijagasTheme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
