import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, updateHeaders } from "./apiCalls";
import config from "./config.json";
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KarticaIos } from "../model/karticaIos/karticaIosModel";

const address = config["razvoj"]  ? config["srbijagas.karIos.razvoj"] : config["srbijagas.karIos"];
const domain = "/karticaIos";

export interface KarticaIosFilter extends PaginationQuery {
    partneri?: number[];
    mestaIsporuke?: number[];
    datumOd?: string;
    datumDo?: string;
    status?: number;
}

/** Vraca PageableWithData<KarticaIos> kada se posalje get request sa ovim url-om */
export function getKarticaIosUlogovanog(query?: KarticaIosFilter){
    return `${address}${domain}${fixQuery(createQueryParams(query))}`;
}

export const karticaIosApi = createApi({
    reducerPath: "karticaIos",
    baseQuery: fetchBaseQuery({
        baseUrl: `${address}${domain}`,
        prepareHeaders: (headers) => updateHeaders(headers)
    }),
    endpoints: (builder)=>({
        getKaricuIos: builder.query<DigitResponse<PageableWithData<KarticaIos>>,KarticaIosFilter|void>({
            query: (filter?: KarticaIosFilter)=>`${fixQuery(createQueryParams(filter))}`
        })
    })
})

export const {useGetKaricuIosQuery} = karticaIosApi
