import { DigitResponse, Row } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, downloadPdfResource, fetchResource, signRequest, updateHeaders } from "./apiCalls";
import config from "./config.json";

/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
   import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ZakljucenjeUgovoraBody, ZakljucenjeUgovoraModel } from "../model/zahtevi/zakljucenjeUgovoraModel";
import { OsnovReklamacije, Prilog, StatusRealizacije, VrstaPriloga, VrstaZahteva } from "../model/zahtevi/sifarniciModel";
import { EDostavaBody, EDostavaModel } from "../model/zahtevi/elektronskaDostavaModel";
import { Zahtev } from "../model/zahtevi/opstiZahtevModel";
import { ZahtevAdresaRacunaBody, ZahtevPromenaAdreseRacunaModel } from "../model/zahtevi/promenaAdreseRacunaModel";
import { ZahtevIsporukaModel, ZahtevIsporukeBody } from "../model/zahtevi/isporukaModel";
import { ZahtevObustavaBody, ZahtevSObustavaIsporukeModel } from "../model/zahtevi/obustavaIsporukeModel";
import { ZahtevPromenaLicnihInfoBody, ZahtevPromenaLicnihInfoModel } from "../model/zahtevi/promenaLicnihInfoModel";
import { ZahtevReklamacijaBody, ZahtevReklamacijaModel } from "../model/zahtevi/reklamacijaModel";

const address = config["razvoj"]  ? config["srbijagas.zahtevi.razvoj"] : config["srbijagas.zahtevi"];
const domain = "/zahtev";

export interface ZahteviFilter extends PaginationQuery {
    partneri?: number[];
	vrsteZahteva?: number[];
	
	datumKreiranjaOd?: string;
	datumKreiranjaDo?: string;
	
	status?: number;
}

function getPdfZahteva(idZahteva: number){
    return `${address}${domain}/pdf/${idZahteva}`;
}

export function getPrazanPdf (jasperName : string){
    return `${address}${domain}/prazanPdf/${jasperName}`;
}

export function fetchZahtevPdf(idZahteva: number): Promise<string|null|DigitResponse<any>>{
    return fetchResource(getPdfZahteva(idZahteva));
}

export function fetchZahtevPdfJasper(jasperName: string): Promise<string|null|DigitResponse<any>>{
    return fetchResource(getPrazanPdf(jasperName));
}

export function downloadZahtevPdf(idZahteva: number, fileName: string): Promise<null|DigitResponse<any>>{
    return downloadPdfResource(getPdfZahteva(idZahteva), fileName);
}

export function getPrilogZahteva(idPriloga: number){
    return `${address}${domain}/prilog/${idPriloga}`;    
}

interface OdbijanjeZahtevaData {idZahteva: number, razlog: string}

export const zahteviApi = createApi({
    reducerPath: "zahtevi",
    baseQuery: fetchBaseQuery({
        baseUrl:`${address}${domain}`,
        prepareHeaders: (headers) => updateHeaders(headers)
    }),
    tagTypes: ["Zahtevi"],
    // https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example
    endpoints: (builder)=>({
        getVrstePriloga: builder.query<DigitResponse<VrstaPriloga[]>, void>({
            query: ()=> "vrstePriloga"
        }),
        getVrsteZahteva: builder.query<DigitResponse<VrstaZahteva[]>, void>({
            query: ()=> "vrsteZahteva"
        }),
        getOsnovReklamacije: builder.query<DigitResponse<OsnovReklamacije[]>, void>({
            query: ()=> "osnovReklamacije"
        }),
        getStatusiRealizacije: builder.query<DigitResponse<StatusRealizacije[]>, void>({
            query: ()=> "statusiRealizacije"
        }),
        getZahteve: builder.query({
            query: (filter?: ZahteviFilter)=>`${fixQuery(createQueryParams(filter))}`,
            providesTags: (result)=> result ? 
            // TODO: check result deo gde se pravi type i id
                [...(result.content?.content?.map((z:any)=>({type: "Zahtevi", id: z.zahtevId})) || []), 
                    {type: "Zahtevi", id: "LISTA_ZAHTEVA"}
                ] : 
                [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}],
        }),
        // mutacije podataka
        postZahtevEDostava: builder.mutation<DigitResponse<EDostavaModel>, Partial<EDostavaBody>>({
            query(body){
                return {
                    url: 'kreiraj/eDostava',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevZakljUgovora: builder.mutation<DigitResponse<ZakljucenjeUgovoraModel>, ZakljucenjeUgovoraBody>({
            query(body){
                return {
                    url: 'kreiraj/zakljucenjeUgovora',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevIsporuka: builder.mutation<DigitResponse<ZahtevIsporukaModel>, ZahtevIsporukeBody>({
            query(body){
                return {
                    url: 'kreiraj/isporuka',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevObustava: builder.mutation<DigitResponse<ZahtevSObustavaIsporukeModel>, ZahtevObustavaBody>({
            query(body){
                return {
                    url: 'kreiraj/obustavaIsporuke',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevAdresaRacuna: builder.mutation<DigitResponse<ZahtevPromenaAdreseRacunaModel>, ZahtevAdresaRacunaBody>({
            query(body){
                return {
                    url: 'kreiraj/adresaRacuna',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevPromenaInfo: builder.mutation<DigitResponse<ZahtevPromenaLicnihInfoModel>, ZahtevPromenaLicnihInfoBody>({
            query(body){
                return {
                    url: 'kreiraj/promenaInfo',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevReklamacija: builder.mutation<DigitResponse<ZahtevReklamacijaModel>, ZahtevReklamacijaBody>({
            query(body){
                return {
                    url: 'kreiraj/reklamacija',
                    method: "POST",
                    body
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        getZahtevOdobri: builder.mutation<DigitResponse<string>, number>({
            query(idZahteva: number){
                return {
                    url: `odobri/${idZahteva}`,
                    method: "GET",
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        postZahtevOdbij: builder.mutation<DigitResponse<string>, OdbijanjeZahtevaData>({
            query(body:OdbijanjeZahtevaData){
                return {
                    url: `odbij/${body.idZahteva}`,
                    method: "POST",
                    body: {razlog: body.razlog}
                }
            },
            invalidatesTags: [{type: "Zahtevi", id: "LISTA_ZAHTEVA"}]
        }),
        getPriloziZahteva: builder.query<DigitResponse<Prilog[]>, number>({
            query: (idZahteva: number)=>`prilozi/${idZahteva}`
        }),

    })
})

export const {
    // dohvatanje podataka
    useGetVrstePrilogaQuery,
    useGetVrsteZahtevaQuery,
    useGetOsnovReklamacijeQuery,
    useGetStatusiRealizacijeQuery,
    useGetZahteveQuery,
    useGetPriloziZahtevaQuery,
    // mutacije podataka
    usePostZahtevEDostavaMutation,
    usePostZahtevIsporukaMutation,
    usePostZahtevZakljUgovoraMutation,
    usePostZahtevAdresaRacunaMutation,
    usePostZahtevPromenaInfoMutation,
    usePostZahtevObustavaMutation,
    usePostZahtevOdbijMutation,
    usePostZahtevReklamacijaMutation,
    useGetZahtevOdobriMutation,
} = zahteviApi;