import i18next from "i18next";
import { lazy } from "react";
import { SGAS_ROLE } from "../../../../model/role";
import { MenuItem } from "../Meni";

const Obavestenja = lazy(()=> import("../../../../pages/mestaIsporuke/Obavestenja"));
const PregledMesta = lazy(()=> import("../../../../pages/mestaIsporuke/PregledMesta"));
const PregledUgovora = lazy(()=> import("../../../../pages/mestaIsporuke/PregledUgovora"));

type MestaIsporukeMenuType = {
    pregledMesta: MenuItem;
    pregledUgovora: MenuItem;
    obavestenja: MenuItem;
}

export default function mestaIsporukeMenu(): MestaIsporukeMenuType{
    return {
        pregledMesta:{
            to: "/mestaIsporuke/pregledMesta",
            id: "pregledMesta",
            title: i18next.t("mesta-isporuke.pregledMesta.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: PregledMesta
        },
        pregledUgovora:{
            to: "/mestaIsporuke/pregledUgovora",
            id: "pregledUgovora",
            title: i18next.t("mesta-isporuke.pregledUgovora.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            hiddenForFizicko: true,
            component: PregledUgovora
        },
        obavestenja:{
            to: "/mestaIsporuke/obavestenja",
            id: "obavestenja",
            title: i18next.t("mesta-isporuke.obavestenja.headerTitle"),
            rolesNeeded: [SGAS_ROLE.ROLE_AUTH_USER],
            component: Obavestenja
        },
    }
}