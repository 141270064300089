
/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TuzbaModel } from "../model/tuzbe/tuzbaModel";
import { DigitResponse } from "../util/digitInterfaces";
import { PageableWithData, PaginationQuery } from "../util/hooks/usePagination";
import { fixQuery } from "../util/util";
import { createQueryParams, updateHeaders } from "./apiCalls";
import config from "./config.json";

const address = config["razvoj"]  ? config["srbijagas.tuzba.razvoj"] : config["srbijagas.tuzba"];
const domain = "/tuzba";

export function getTuzbeBaseQuery(){
    return `${address}${domain}`
}

export interface TuzbeFilter extends PaginationQuery{
    partneri?: number[];
    sifreMesta?: string[];
    brojPredmeta?: string[];
    status?: string;
}

export const tuzbeApi = createApi({
    reducerPath: "tuzbe",
    baseQuery:fetchBaseQuery({
        baseUrl: getTuzbeBaseQuery(),
        prepareHeaders: (headers)=>updateHeaders(headers)
    }),
    endpoints: (builder)=>({
        getTuzbe: builder.query<DigitResponse<PageableWithData<TuzbaModel>>, TuzbeFilter|void>({
            query: (filter?: TuzbeFilter)=>`${fixQuery(createQueryParams(filter))}`
        }),
        getStatuseTuzbi: builder.query<DigitResponse<string[]>, void>({
            query: ()=>`/statusi`
        })
    })
})

export const {useGetTuzbeQuery,
    useGetStatuseTuzbiQuery
} = tuzbeApi