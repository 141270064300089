import { Box, Container, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { colors } from "../../util/konstante";
import SGAuth from "./auth/SGAuth";
import SGasMenu from "./menu/Meni";
import { MenuHorizontalNav } from "./menu/MenuHorizontalNav";

const useStylesHeader = (): {[key: string]:SxProps<Theme>}=>({
    root: {
        color: "primary.main",
        padding: "1em 2em !important",
        display: "flex",
        maxWidth: "100% !important",
        alignItems: "center",
        "& > *":{
            marginRight: "2em",
        },
    },
    gradientDivider: {
        background: theme => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 90%)`,
        height: "0.2em",
    },
    breadcrumbs: {
        "& a":{
            textDecoration: "none",
            color: "unset",
        }
    },
    logoWrapper: {
        display: "inline-flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "flex-start",
        fontSize: "2rem",
        fontStyle:"italic",
        color: colors.logoText,
        fontWeight: 800,
        "&>*":{
            marginRight: "15px"
        },
        "&:hover":{
            cursor:"pointer"
        }
    },
    horizontalMenuBar:{
        position:"sticky",
        top:0,
        left:0,
        zIndex:100,
        maxWidth: "100% !important",
        background: theme => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 90%)`,
        color: "#ffffff !important",
        "& *":{
            color: "#ffffff !important",
        },
        padding: "0.1em 1em",
    }
});

interface HeaderProps{
}

function Header(props: WithTranslation&PropsWithChildren<HeaderProps>){
    const classes = useStylesHeader();
    const history = useHistory();

    useEffect(()=>{},[props]);

    const naPocetnu = ()=>{
        history.push(SGasMenu().pocetna.to)
    }
    const renderChildren = () => {
        if (Array.isArray(props.children)) {
            return props.children.map((child: JSX.Element) => <Box key={child.key}>{child}</Box>)
        }
        return <Box sx={{display: "flex"}}>{props.children}</Box>;
    }
    return (
        <>
            <Container component="div" sx={classes.root}>
                <Box sx={classes.logoWrapper} onClick={naPocetnu}>
                    
                    {/* but why */}
                    <img src={"/Moj Srbijagas LOGO (1).png"} alt={"logo"} 
                        style={{display: "inline-flex", width: "15rem", height: "auto", alignSelf: "center"}}
                    />
                    {/* <img src={"/sgas-40.png"} alt={"logo"} style={{display: "inline-flex", height: "fit-content", alignSelf: "center"}}/>
                    <span>{t('common.moj-srbijagas')}</span> */}
                </Box>
                
            </Container>
            <Container component="div" maxWidth="xl" sx={classes.horizontalMenuBar}>
                <MenuHorizontalNav menu={Object.values(SGasMenu())}>
                    <Box style={{flexGrow: 1}}/>
                    <Box>
                        <SGAuth/>
                    </Box>
                    {renderChildren()}
                </MenuHorizontalNav>
            </Container>
        </>
    )
}

export default withTranslation()(Header);