import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import loginSlice from './model/login/loginSlice'
import { karticaIosApi } from './network/karticaIosApiCalls'
import { zahteviApi } from './network/zahteviApiCalls'
import { novostiApi } from './network/novostiApiCalls'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { fakturaApi } from './network/fakturaApiCalls'
import { finKarticaApi } from './network/finKarticaApiCalls'
import { korisnikApi } from './network/kportalaApiCalls'
import { mestaIsporukeApi } from './network/mestaIsporukeApiCalls'
import { partnerApi } from './network/ppartnerApiCalls'
import { tuzbeApi } from './network/tuzbeApiCalls'
import { ugovorApi } from './network/ugovorApiCalls'
import { iopApi } from './network/obPlacanjaApiCalls'
import { registracijaApi } from './network/registracijaApiCalls'
import { registracijaAdminaApi } from './network/registracijaAdminaApi'


const persistConfig = {
    key: 'root',
    storage,
    whitelist:[],
    // blacklist:[]
}

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
    login: loginSlice,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [fakturaApi.reducerPath]: fakturaApi.reducer,
    [korisnikApi.reducerPath]: korisnikApi.reducer,
    [finKarticaApi.reducerPath]: finKarticaApi.reducer,
    [mestaIsporukeApi.reducerPath]: mestaIsporukeApi.reducer,
    [ugovorApi.reducerPath] : ugovorApi.reducer,
    [tuzbeApi.reducerPath]: tuzbeApi.reducer,
    [karticaIosApi.reducerPath]: karticaIosApi.reducer,
    [zahteviApi.reducerPath]: zahteviApi.reducer,
    [novostiApi.reducerPath]: novostiApi.reducer,
    [iopApi.reducerPath]: iopApi.reducer,
    [registracijaApi.reducerPath]: registracijaApi.reducer,
    [registracijaAdminaApi.reducerPath]: registracijaAdminaApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(partnerApi.middleware)
            .concat(fakturaApi.middleware)
            .concat(korisnikApi.middleware)
            .concat(mestaIsporukeApi.middleware)
            .concat(finKarticaApi.middleware)
            .concat(tuzbeApi.middleware)
            .concat(ugovorApi.middleware)
            .concat(zahteviApi.middleware)
            .concat(karticaIosApi.middleware)
            .concat(novostiApi.middleware)
            .concat(iopApi.middleware)
            .concat(registracijaApi.middleware)
            .concat(registracijaAdminaApi.middleware)
        })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store
