import { SortDirection } from "@mui/material";
import i18next from "i18next";
import moment from "moment-timezone";
import sanitizeHtml from "sanitize-html";
import formElements from "../components/form/formElements/formElements";
import { FormElement } from "../components/form/useForm";
import { CustomFormater, FieldInfo, FieldType, ObradaZahteva, Row, ValidationAns } from "./digitInterfaces";
import { appNames, FORMAT_DATE, FORMAT_DATUM_VREME, FORMAT_DATUM_VREME_BEZ_SS } from "./konstante";

/**
 * Privremeno mapiranje dok se ne predje skroz na sheme, za slucaj da je numericko polje
 * prilikom dohvatanja podataka uraditi transformaciju tako da se polja formatiraju.
 */
const formElementNameMappingToFieldType:{[name: string]: FieldType} = {
  [formElements.checkbox.name]: "checkbox",
  [formElements.datepicker.name]: "date",
  [formElements.text.name]: "text"
}
/**
 * Privremena funckija dok se ne predje skroz na sheme
 */
export function schemaFieldToReadOnlyInfo(attr: string, field: FormElement): FieldInfo{
    return {
      attributeName: attr,
      label: field.label,
      type: formElementNameMappingToFieldType[field.fieldElement.name] 
    }
}

export function setLanguage (selectedLanguage: string) {
  localStorage.setItem(appNames.selectedLanguage, selectedLanguage);
}

export function getLanguage () {
  let lang = localStorage.getItem(appNames.selectedLanguage);
  if (!lang) {
    lang = appNames.DEFAULT_LOCALE;
    setLanguage(lang);
  }
  return lang;
}

export function fixQuery (query?: string) {
  return query ? ((query.startsWith("?") ? query : "?" + query.trim())) : "";
}

/** proverava da li su svi atributi imaju postavljene vrednosti.
 * Proveri da li values sadrzi svaki atribut iz attrNames i da onda 
 * proveri da li svi imaju vrednost koja nije undefined, null ili prazan string
*/
export function allAttrsNonEmpty (values: Row, attrNames: string[]) {
  return attrNames?.every(attrName => {
    return !isEmpty(values[attrName])
  });
}

export function fixHtml (html: string | undefined) {
  if (!html) {
    return "";
  }
  let div = document.createElement('div');
  div.innerHTML = html
  return div.innerHTML;
}

export function stripHtml (html: string | undefined) {
  if (!html) {
    return "";
  }
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export const sanitize = (value: any) => {
  return sanitizeHtml(value, {
    // TODO: proveriti da li je to sve sto treba da bude tu
    allowedTags: ['b', 'i', 'em', 'u', 'span', 'p', 'strong', 'br'],
  })
}

export function showLoadingIndicator (status: ObradaZahteva | undefined, rowLength: number | undefined) {
  return status?.uToku && (rowLength === undefined || rowLength === 0)
}

export function falseIfUndef (val: any) {
  if (val === undefined) {
    return false;
  }
  return val;
}

export function naIfEmpty (value: any) {
  return isEmpty(value) ? i18next.t("common.no-data") : value;
}

export function isEmpty (value: any) {
  return value === undefined || String(value)?.trim() === "" || value == null;
}

export function convertWidthToStyle (width: string | undefined) {
  if (width) {
    return {
      width: width
    }
  }
  return {}
}

// kada treba da se vidi vise kolona nego sto komotno moze da stane u tabelu
export function getStyleObjectMinMaxWidthPx (min: number, max?: number): object {
  return {
    minWidth: min,
    maxWidth: (max !== undefined ? max : min + 50),
    textAlign: "center",
  };
}

export function getValue (attrName: string, row: any): any {
  if (row === undefined) {
    return undefined;
  }
  return Object.values(row)[Object.keys(row).findIndex(k => k === attrName)];
}

export function removeKey (attrName: string, row: Row) {
  let reducedObject: Row = {};
  for (let attr of Object.keys(row)) {
    if (attr !== attrName) {
      reducedObject[attr] = row[attr];
    }
  }
  return reducedObject;
}

/** provera ide sa {@link isEmpty} */
export function removeEmptyValues (values: Row): Row {
  let temp: Row = {};
  for (let attr of Object.keys(values)) {
    let val = getValue(attr, values);
    if (!isEmpty(val)) {
      temp[attr] = val;
    }
  }
  return temp;
}


export function isPhoneNumber (value: string): ValidationAns {
  let isValid = value?.match("(?=[^-0-9/ ])") == null;
  return {
    hasError: !isValid,
    newValue: value?.replace('[^-0-9/]', '')
  };
}


//---------------- FORMATERI


export function formatValue (val: any, fieldInfo: FieldInfo) {
  if (isEmpty(val)) {
    return undefined;
  }
  let formater = getFieldFormater(fieldInfo.type || "text")?.valueFormater;
  if (formater) {
    return formater(val);
  }
  return val;
}

export const numberDeformater = (numStr: any) => {
  // za replace se zali jer mu se verzije ne poklapaju
  let num = String(numStr).replaceAll(".", '').replaceAll(',', '.');
  if (isNaN(+num)) {
    return undefined;
  }
  return +num;
}
export const customFormaterNumber: CustomFormater = {
  valueFormater: (val) => formatNumber(+val),
  valueDeformater: (val) => numberDeformater(val)
}
export const customFormaterNumberNoDec: CustomFormater = {
  valueFormater: (val) => formatNumberNoDec(+val),
  valueDeformater: (val) => numberDeformater(val)
}

export const customFormaterDate: CustomFormater = {
  valueDeformater: (formatedDate: string) => {
    if (formatedDate === undefined || formatedDate == null)
      return undefined;
    return dateFromString(formatedDate);
  },
  valueFormater: (strDate: string) => {
    if (strDate === undefined || strDate === "" || strDate == null)
      return undefined;
    let date = moment.tz(dateFromString(strDate), "Europe/Belgrade");
    return date.format(FORMAT_DATE);
  }
}

export const customFormaterDateTime: CustomFormater = {
  valueDeformater: (formatedDate: string) => {
    return dateFromString(formatedDate);
  },
  valueFormater: (strDate: string) => {
    let date = moment.tz(dateFromString(strDate), "Europe/Belgrade");
    if (date.get('hour') === 0 && date.get('minute') === 0 && date.get('second') === 0) {
      return date.format(FORMAT_DATE);
    }
    else {
      return date.format(FORMAT_DATUM_VREME);
    }
  }
}
export function getFieldFormater (fieldType: FieldType): CustomFormater | undefined {
  if (fieldType) {
    if (fieldType === "numeric") {
      return customFormaterNumber;
    }
    else if (fieldType === "numeric-no-dec") {
      return customFormaterNumberNoDec;
    }
    else if (fieldType === "date") {
      return customFormaterDate;
    }
    else if (fieldType === "datetime-local") {
      return customFormaterDateTime;
    }
  }
  return undefined;
}


export const INVALID_DATE = 'Invalid date';

export function isFirstBeforeSecondDate (fstDate: string, sndDate: string): boolean {
  // ne ignorise vremenske zone!
  // TODO: naci bolji nacin za ignorisanje zona i poredjenje datuma, ili porediti sa zonama?
  // let fst = moment(dateFromString(moment(dateFromString(fstDate)).startOf('day').format(FORMAT_DATE)));
  // let snd = moment(dateFromString(moment(dateFromString(sndDate)).startOf('day').format(FORMAT_DATE)));  
  let fst = moment.tz(dateFromString(fstDate), "Europe/Belgrade").startOf('day');
  let snd = moment.tz(dateFromString(sndDate), "Europe/Belgrade").startOf('day');
  return fst.isBefore(snd);
}

export function areSameDates (fstDate: string, sndDate: string): boolean {
  // ignorise vremenske zone!
  let fst = moment.tz(dateFromString(fstDate), "Europe/Belgrade").format(FORMAT_DATE);
  let snd = moment.tz(dateFromString(sndDate), "Europe/Belgrade").format(FORMAT_DATE);
  return fst === snd;
}

export function areSameMonths (fstDate: string, sndDate: string): boolean {
  let date1 = moment(dateFromString(fstDate)).toDate();
  let date2 = moment(dateFromString(sndDate)).toDate();
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function getToday () {
  return moment.tz(new Date(), "Europe/Belgrade").format();
}

export const dateFromString = (str: string): string => {
  let checkDate = moment.tz(str, FORMAT_DATUM_VREME, true, "Europe/Belgrade").format();

  if (checkDate === INVALID_DATE) {
    checkDate = moment.tz(str, FORMAT_DATUM_VREME_BEZ_SS, true, "Europe/Belgrade").format();
    if (checkDate === INVALID_DATE) {
      checkDate = moment.tz(str, FORMAT_DATE, true, "Europe/Belgrade").format();
      if (checkDate === INVALID_DATE) {
        checkDate = moment.tz(str, "Europe/Belgrade").format();
      }
    }
  }
  return checkDate !== INVALID_DATE ? checkDate : str;
}

export const formatedDate = (str: string, format?: string): string => {
  let checkDate = dateFromString(str);
  checkDate = moment.tz(checkDate, "Europe/Belgrade").format(format !== undefined ? format : FORMAT_DATE);
  if (checkDate === INVALID_DATE) {
    return str;
  }
  return checkDate;
}

export type Order = 'asc' | 'desc' | SortDirection;

export const numberComparator = (a: number, b: number) => {
  return a - b;
}
export const stringComparator = (a: string, b: string) => {
  return String(a).localeCompare(String(b)); // da ne pukne ako mu dodje nesto sto nije string
}
export const dateComparator = (a: string, b: string) => {
  // console.log(moment(dateFromString(a)).format(), moment(dateFromString(b)).format()) // prikazuje vremenske zone! npr sifarnik svp
  if (a === undefined || a === "") {
    return 1;
  }
  if (b === undefined || b === "") {
    return -1;
  }
  if (moment(dateFromString(a)).format(FORMAT_DATUM_VREME) === moment(dateFromString(b)).format(FORMAT_DATUM_VREME)) {
    return 0;
  }
  return moment(dateFromString(a)).isBefore(moment(dateFromString(b))) ? -1 : 1;
}
export const compareBasedOnType = (a: any, b: any, typeInfo: FieldType | undefined) => {
  try {
    if (typeInfo === undefined) {
      typeInfo = "text";
    }
    if (typeInfo.includes("number") || typeInfo.includes("numeric")) {
      return numberComparator(a, b);
    }
    if (typeInfo.includes("date")) {
      return dateComparator(a, b);
    }
    else {
      return stringComparator(a, b);
    }
  } catch (err) {
    console.error(err);
  }
  return 0;
}

export const formatNumber = (num: number | null | undefined, maxDecimalPlaces?: number): string | null => {
  if (num === undefined || num == null) {
    return null;
  }
  let minDecPlaces = 2
  let maxDecPlaces = maxDecimalPlaces ? maxDecimalPlaces : 2
  if (maxDecimalPlaces && maxDecimalPlaces < minDecPlaces) {
    minDecPlaces = maxDecimalPlaces;
  }
  const options = {
    minimumFractionDigits: minDecPlaces,
    maximumFractionDigits: maxDecPlaces
  };
  let formated = num.toLocaleString("pt-BR", options);
  if (isNaN(num)) {
    return "";
  }
  return formated;
}

export const formatNumberDefaultZero = (num: number) => {
  let formated = formatNumber(num);
  return formated == null ? formatNumber(0) : formated;
}

export const formatNumberNoDec = (num: number): string | null => {
  if (isEmpty(num)) {
    return null;
  }
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };
  let formated = num.toLocaleString("pt-BR", options);
  if (isNaN(num)) {
    return null;
  }
  return formated;
}

export const formatNumberNoDecZeroPrefix = (num: number, minIntDigits?: number): string | null => {
  if (isEmpty(num)) {
    return null;
  }
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumIntegerDigits: minIntDigits ? minIntDigits : 2
  };
  let formated = num.toLocaleString("pt-BR", options);
  if (isNaN(num)) {
    return null;
  }
  return formated;
}
