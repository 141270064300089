/***
 * @template T - tip odgovora sa servera, moze biti json objekat ili resurs
 */
export type DigitResponse<T> = {
    content: T | null;
    message: string;
    hasMsg: boolean;
}


export type FieldGroup = {
    fields: FieldInfo[];
    customFields?: JSX.Element;
    id: string;
    title?: string;
    subtitle?: string;
    /** TODO: Za sortiranje grupa ukoliko treba da se overideuje postojece. Prvo se prikazuju grupe koje imaju ovaj atribut. */
    sort?: number;
    separatorBefore?: boolean;
}

export type ColorOptions = "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined;

export type StatusRacuna = "placen" | "delimicnoPlacen" | "nijePlacen" | "utuzen" | "storniran";

export interface FetchedData {
    successful: boolean;
    response: any;
    id?: any;
}

export interface FetchedFileData extends FetchedData {
    fileName: string;
    id: any;
}

export type ContentHeader = { headerTitle: string; headerMsg: string[] | string; }
// export type NavigationInfo = DNavItem & ContentHeader

export type Row = { [attrName: string]: any }

export interface RequestProcessing {
    inProgress: boolean;
    msg: string | undefined;
    successful: boolean | undefined;
}

export function getInitRequestProcessing (): RequestProcessing {
    return {
        inProgress: false,
        msg: undefined,
        successful: undefined
    }
}

export interface ValidationAns {
    hasError: boolean,
    errorMsg?: string,
    newValue?: any
}

export type FieldValidations = {
    [attrName: string]: ValidationAns;
}

export type FieldType = ("numeric-no-format" | "numeric" | "numeric-no-dec" |
    "date" | "datetime-local" | "time" | "month-year"
    | "text" | "checkbox" | "list" | "single-select" | "multiline-text");

export interface CustomFormater {
    valueFormater: (value: any) => any;
    valueDeformater: (value: any) => any;
}

export interface FieldInfo {
    attributeName: string;
    label?: string,
    type?: FieldType,
    required?: boolean,
    /** validaciona funkcija, namenjena da se rezultatom popuni 'error' deo */
    validation?: (value: any) => ValidationAns,
    error?: ValidationAns,
    /** Koristi osim formatiranja vrednosti za brojeve i datume i kao funkcija koja za
     * checkbox odredjuje da li treba da bude selektovan (valueFormater) 
     * i kada se toggle-uje da se zna koju vrednost zapravo treba da uzme (valueDeformater).
     */
    customFormater?: CustomFormater,
    /** dropdown koji ima props: ObjectsDropdownProps&SifarnikDodatak */
    dropdown?: JSX.Element,
    fieldWidth?: string,
    editable?: boolean;

    /** redosled pojavljivanja ukoliko ima potrebe da se overrideuje podrazumevani */
    sort?: number;
    decimalPlaces?: number;
    /** broj linija za multilineText */
    lines?: number;
    /** default je true, ako je postavljeno na false nece biti moguce sortiranje po koloni */
    sortable?: boolean;
}



export interface ObjectsDropdownProps {
    label?: string,
    returnAttr?: string,
    onValueSelected?: (selected: any | any[]) => void,
    currentSelection?: Object,
    customClass?: any,
    size?: "small" | "medium",
    variant?: "outlined" | "standard" | "filled",
    miniWidth?: boolean,
    smallWidth?: boolean,
    fullWidth?: boolean,
    required?: boolean,
    error?: boolean,
    errorMsg?: string,
    inline?: boolean,
    propertyToShow?: string,
    sortByProperty?: string,
    /** ako je naveden property formater ignorisace koji property treba da prikaze i koristice ovo za konstrukciju */
    propertyFormater?: (value: object) => string,
    multiselect?: boolean,
    displaySelectedTags?: number,
    getRowId?: (row: any) => any;
    passedCurrentAttrValue?: string,
    disabled?: boolean,
    styles?: { // koristi se u DigitInlineField
        labelWidth?: string,
        fieldWidth?: string,
    }
}


export interface ObradaZahteva {
    uToku: boolean;
    poruka?: string;
    /** Ako je undefined znaci da nije ni poslat zahtev */
    uspesno: boolean | undefined;
}
export function initObradaZahteva (): ObradaZahteva {
    return {
        uToku: false,
        poruka: undefined,
        uspesno: undefined
    };
}


export interface KeycloakRespose {
    accessToken: string;
    expriresIn: number;
    refreshExpiresIn: number;
    refreshToken: string;
    tokenType: string;
    notBeforePolicy: number;
    sessionState: string;
    scope: string;
    error: string;
}


export interface DisabledActions {
    delete?: boolean;
    edit?: boolean;
    insert?: boolean;
}


export interface Search<R> {
    /** u slucaju da imaju sacuvane vrednosti filtera prosledjuje dalje filter komponenti */
    filterValues: Partial<R>;
    onFilter: (filterValues: Partial<R>) => void;
}
